var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"he"},[_c('div',{staticClass:"about-hero"},[_c('router-link',{staticClass:"logo-wrap",attrs:{"to":"/he/about"}},[_c('img',{attrs:{"src":require("../assets/logo-white.svg"),"height":"","width":""}})]),_c('div',{staticClass:"cont-top"},[_c('a',{staticClass:"whatsapp",attrs:{"href":"https://wa.me/message/YC3WILNVSVUXN1"},on:{"click":function($event){return _vm.sendAnalytics('click', 'button', 'whatsapp')}}},[_c('img',{attrs:{"src":require("../assets/whatsapp.svg"),"height":"","width":""}}),_vm._v(" דברו איתנו ")]),_c('a',{staticClass:"cont-phone",attrs:{"href":"tel:+972-50-595-2713"}},[_vm._v("050-595-2713")])]),_c('router-link',{staticClass:"lang",attrs:{"to":`/en/about`}},[_vm._v("English")]),_vm._m(0),_c('img',{staticClass:"king",attrs:{"src":require("../assets/vr-boy.png"),"alt":"vr"}}),_c('img',{staticClass:"about-bg",attrs:{"src":require("../assets/bgw.jpg")}})],1),_c('p'),(_vm.tours.length)?_c('carousel',{staticClass:"about-tours-wrap",attrs:{"nav":false,"autoplay":true,"autoplayTimeout":7000,"responsive":{
    0: { items: 1 },
    800: { items: Math.min(3, _vm.tours.length) },
  }}},_vm._l((_vm.tours),function(tour){return _c('router-link',{key:tour._id,staticClass:"about-tour-link",attrs:{"to":`/he/${tour._id}`,"href":""}},[_c('div',{staticClass:"about-tour-link-txt-box"},[_c('p',{staticClass:"about-tour-link-head"},[_vm._v(_vm._s(tour.nameHebrew))]),_c('p',[_vm._v("לרכישת כרטיסים")])]),((tour.coverImage || {}).extension === '.mp4')?_c('video',{staticStyle:{"pointer-events":"none"},attrs:{"playsinline":"","autoplay":"","mute":"","loop":"","poster":tour.fallback.imageURL,"preload":"metadata"}},[_c('source',{attrs:{"src":tour.coverImageMov.imageURL,"type":"video/mp4"}}),_c('source',{attrs:{"src":tour.coverImageMov.imageURL,"type":"video/mov"}}),_c('source',{attrs:{"src":tour.coverImage.imageURL,"type":"video/mp4"}}),_c('source',{attrs:{"src":tour.coverImage.imageURL,"type":"video/webm"}})]):_c('img',{attrs:{"src":tour.coverImage.imageURL}})])}),1):_vm._e(),_vm._m(1),_vm._m(2),_vm._m(3)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-hero-wrap"},[_c('div',{staticClass:"about-hero-txt-wrap"},[_c('h1',{staticClass:"about-head"},[_vm._v("סיורי VR חוויה לכל המשפחה!")]),_c('p',{staticClass:"about-sub"},[_vm._v(" בואו להיות חלק ממסע בזמן ולגלות את ירושלים במציאות מדומה. הסיפורים ההיסטוריים והדרמות הגדולות כפי שמעולם לא ראיתם!סיורים במספר אתרים : עיר דוד , מרכז דוידסון , קניון ים המלח ומתחם התחנה בירושלים חוויה לכל המשפחה המועבר בטכנולוגיה חדשנית, באמצעות משקפי מציאות מדומה והדרכה וירטואלית, המעניקים צבע, קול ותחושה לסיפורים של ירושלים. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"about-mid-head"},[_vm._v(" \"סיור רגלי מרהיב בירושלים\" "),_c('br'),_vm._v(" \"מראה ושמע תלת ממדי מדהימים\" ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-video-wrap"},[_c('div',{staticClass:"video"},[_c('iframe',{attrs:{"width":"560","height":"315","src":"https://www.youtube.com/embed/3f1_fCbtyng?controls=0","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-testimonials-wrap"},[_c('div',{staticClass:"about-testimonial"},[_c('p',[_vm._v(" לא דומה לשום סיור אחר שם בחוץ. האנימציות המדהימות לא רק בונות מחדש את האתר העתיק לנגד עיניך, אלא גם מחזירות אותך בזמן בחזרה אל הגיבורים והסיפורים התנ\"כיים. ")]),_c('div',{staticClass:"about-stars-wrap"},[_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}}),_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}}),_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}}),_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}})]),_c('p',{staticClass:"about-name"},[_vm._v("ג'ודי מינרד")]),_c('p',{staticClass:"about-loc"},[_vm._v("סידני, אוסטרליה")])]),_c('div',{staticClass:"about-testimonial"},[_c('p',[_vm._v(" שילוב מרתק של היסטוריה, ארכיאולוגיה ודרמה. מרגש לראות את הסיפורים הישנים מתעוררים לחיים ")]),_c('div',{staticClass:"about-stars-wrap"},[_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}}),_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}}),_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}}),_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}}),_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}})]),_c('p',{staticClass:"about-name"},[_vm._v("ירון שוורץ")]),_c('p',{staticClass:"about-loc"},[_vm._v("רחובות")])])])
}]

export { render, staticRenderFns }