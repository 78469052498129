<template>
  <div style="max-width: 1200px; margin: auto">
    <checkfront
      :productID="query.checkfrontProductID || '3'"
      :language="query.language || 'en'"
    ></checkfront>
  </div>
</template>

<script>
import Checkfront from "@/components/CheckFront";
export default {
  components: {
    Checkfront,
  },
  computed: {
    query() {
      return this.$route.query;
    },
  },
};
</script>
