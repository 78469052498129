<script>
export default {
  name: "paypal-button",
  props: {
    allowSubmit: {
      type: Boolean,
      default: true,
    },
    productionKey: String,
    amount: [Number, String],
    description: String,
  },
  data() {
    return {
      timeout: null,
      resetPaypal: false,
    };
  },
  mounted() {
    this.loadPaypal();
  },
  watch: {
    allowSubmit() {
      if (this.allowSubmit) this.amountUpdate();
    },
    amount() {
      this.amountUpdate();
    },
  },
  methods: {
    emitClick(event) {
      this.$emit("click", event);
    },
    loadPaypal() {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = `https://www.paypal.com/sdk/js?client-id=${this.productionKey}&currency=ILS`;
      document.head.appendChild(s);
      this.paypalTimout();
    },
    paypalTimout() {
      setTimeout(() => {
        if (window.paypal != undefined) {
          this.setPayPal();
        } else this.paypalTimout();
      }, 500);
    },
    amountUpdate() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.resetPaypal = true;
        setTimeout(() => {
          this.resetPaypal = false;
          setTimeout(() => {
            this.setPayPal();
          }, 1);
        }, 1);
        this.paypalLoaded = true;
      }, 500);
    },
    setPayPal() {
      let purchase_units = [
        {
          description: this.description,
          amount: {
            currency_code: "ILS",
            value: this.amount ? parseInt(this.amount) : 0,
          },
        },
      ];
      window.paypal
        .Buttons({
          style: {
            shape: "pill",
            label: "checkout",
          },
          fundingSource: window.paypal.FUNDING.PAYPAL,
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: purchase_units,
            });
          },
          onApprove: async (data, actions) => {
            await actions.order.capture();
            // this.$emit("approve", order);
            this.$emit("approve", true);
          },
          onError: (err) => this.$emit("error", err),
        })
        .render(this.$refs.paypalBtn);
    },
  },
};
</script>

<template>
  <div class="paypalButton">
    <div v-if="!resetPaypal && allowSubmit">
      <div ref="paypalBtn"></div>
    </div>
    <button @click="emitClick($event)" v-else class="pill" />
  </div>
</template>
<style scoped>
.pill {
  display: block;
  width: 95%;
  height: 55px;
  border-radius: 100px;
  background: #ffc439;
  margin: auto;
}
.pill:after {
  content: "Fill all required fields";
  line-height: 55px;
  color: #9f730e;
}

.paypalButton {
  text-align: center;
  min-height: 65px;
  max-width: 500px;
  width: 90%;
}
@media screen and (max-width: 480px) {
  .pill {
    width: 100%;
    height: 45px;
    margin: 0 0px 0 0px;
  }
  .pill:after {
    line-height: 45px;
  }
}
</style>
