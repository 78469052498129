
/* eslint-disable */
import { defineComponent } from "@vue/composition-api";
import SuccessAnimation from "../components/successAnimation.vue";


export default defineComponent({
  name: "Success",
  layout: "empty",
  components: { SuccessAnimation },
  emits: ["sendAnalytics"],
  props: {
    bookingID: {
      type: [Number, String],
      default: "",
    },
    isPaypal: {
      type: Boolean,
      default: false,
    },
    paypalTotal: {
      type: [Number, String],
      default: undefined,
    },
    productData: {
      type: Object,
      default: undefined,
    },
  },
  mounted() {
    console.log(window.location.search)
    if (!this.bookingID) return;
    // (transaction_id: any, affiliation: any, value: any, args?: {})
    this.$emit(
      "sendAnalytics",
      this.bookingID,
      this.isPaypal ? "Paypal" : "N/A",
      this.paypalTotal ? +this.paypalTotal : 0
    );
    // convertPixel({
    //   bookingID: this.bookingID,
    //   productName: "Tour",
    //   quantity: 1,
    //   unitCost: 1,
    //   total: this.paypalTotal,
    // });
  },
});
