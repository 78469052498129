var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('popup',{attrs:{"width":"90%"},model:{value:(_vm.popupImage),callback:function ($$v) {_vm.popupImage=$$v},expression:"popupImage"}},[_c('img',{attrs:{"src":_vm.popupImage}})]),_c('popup',{attrs:{"width":"90%"},model:{value:(_vm.popupVideo),callback:function ($$v) {_vm.popupVideo=$$v},expression:"popupVideo"}},[_c('div',{staticClass:"video",domProps:{"innerHTML":_vm._s(_vm.tour.videoLink)}})]),_c('div',{staticClass:"hero"},[_c('router-link',{staticClass:"logo-wrap",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../assets/logo-white.svg")}})]),_c('div',{staticClass:"cont-top"},[_c('a',{staticClass:"whatsapp",attrs:{"href":"https://wa.me/message/YC3WILNVSVUXN1"},on:{"click":function($event){return _vm.sendAnalytics('click', 'button', 'whatsapp')}}},[_c('img',{attrs:{"src":require("../assets/whatsapp.svg")}}),_vm._v(" Start Chat ")]),_c('a',{staticClass:"cont-phone white",attrs:{"href":"tel:+972-50-595-2713"}},[_vm._v("+972-50-595-2713")])]),_c('router-link',{staticClass:"lang",attrs:{"to":`/he/${_vm.$route.params.id}`}},[_vm._v("עברית")]),_c('div',{staticClass:"dark-top"},[_c('div',{staticClass:"hero-txt-box"},[_c('h1',{staticClass:"main-head"},[_vm._v(_vm._s(_vm.tour.nameEnglish))]),_c('p',{staticClass:"book",on:{"click":function($event){_vm.goto('#tickets');
            _vm.sendAnalytics('click', 'button', 'booknow');}}},[_vm._v(" Book Now! ")]),_c('div',{staticClass:"playbtn",on:{"click":function($event){_vm.popupVideo = true}}})])]),(_vm.tour.coverImage)?_c('div',{staticClass:"main-image-wrap"},[((_vm.tour.coverImage || {}).extension === '.mp4')?_c('video',{staticClass:"main-image",staticStyle:{"pointer-events":"none"},attrs:{"autoplay":"","loop":"","muted":"","playsinline":"","poster":_vm.tour.fallback.imageURL,"preload":"metadata"},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.tour.coverImageMov.imageURL,"type":"video/mp4"}}),_c('source',{attrs:{"src":_vm.tour.coverImageMov.imageURL,"type":"video/mov"}}),_c('source',{attrs:{"src":_vm.tour.coverImage.imageURL,"type":"video/mp4"}}),_c('source',{attrs:{"src":_vm.tour.coverImage.imageURL,"type":"video/webm"}})]):_c('img',{staticClass:"main-image",attrs:{"src":_vm.tour.coverImage.imageURL}})]):_vm._e()],1),_c('div',{staticClass:"info-box"},[_c('div',{staticClass:"left-info"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.tour.descriptionEnglish)}}),_c('p',{staticClass:"book",on:{"click":function($event){_vm.goto('#tickets');
          _vm.sendAnalytics('click', 'button', 'booknow');}}},[_vm._v(" Book Now! ")])]),_c('div',{staticClass:"right-info"},[_c('div',{staticClass:"points-wrap"},[_vm._l((_vm.tour.bulletPoint),function(point){return _c('div',{key:point._id,staticClass:"info-list-item"},[_c('div',{staticClass:"info-list-icon-wrap"},[(point.icon[0].item.icon.imageURL)?_c('img',{attrs:{"src":point.icon[0].item.icon.imageURL}}):_vm._e()]),_c('div',{staticClass:"list-txt"},[_c('p',[_vm._v(" "+_vm._s(point.lightText)+" "),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(point.boldText))])]),(point.list)?_c('div',{staticClass:"list-txt-info-box",domProps:{"innerHTML":_vm._s(point.list)}}):_vm._e()])])}),_c('div',{staticClass:"side-line"})],2),_c('p',{staticClass:"bluebtn",on:{"click":function($event){return _vm.goto('#otherExperiences')}}},[_vm._v(" Other Experiences ")])])]),_c('div',{staticClass:"gallery"},_vm._l((_vm.tour.imageGallery),function(image){return _c('div',{key:image.imageURL,staticClass:"gal-image",on:{"click":function($event){_vm.popupImage = image.imageURL}}},[_c('img',{staticClass:"gallery-img",attrs:{"src":image.imageURL,"height":"","width":""}})])}),0),_c('div',{staticClass:"tickets",attrs:{"id":"tickets"}},[_vm._m(0),_c('router-view',{attrs:{"productID":_vm.tour.checkfrontProductID,"language":'en'}})],1),_c('div',{attrs:{"id":"otherExperiences"}},[_c('h3',{staticClass:"tickets-head other"},[_vm._v("Check out our other Experiences")]),(_vm.otherTours.length)?_c('carousel',{staticClass:"other-tours",attrs:{"nav":false,"loop":true,"autoplay":true,"autoplayTimeout":7000,"responsive":{
        0: { items: 1 },
        800: { items: Math.min(3, _vm.otherTours.length) },
      }}},_vm._l((_vm.otherTours),function(otherTour){return _c('div',{key:otherTour._id,staticClass:"other-tour-link-wrap"},[_c('a',{staticClass:"other-tour",attrs:{"href":'/en/' + otherTour._id}},[_c('div',{staticClass:"about-tour-link-txt-box"},[_c('p',[_vm._v(_vm._s(otherTour.nameEnglish))]),_c('p',[_vm._v("Purchase Tickets")])]),((otherTour.coverImage || {}).extension === '.mp4')?_c('video',{staticClass:"other-image",staticStyle:{"pointer-events":"none"},attrs:{"playsinline":"","autoplay":"","muted":"","loop":"","poster":otherTour.fallback.imageURL},domProps:{"muted":true}},[_c('source',{attrs:{"src":otherTour.coverImageMov.imageURL,"type":"video/mp4"}}),_c('source',{attrs:{"src":otherTour.coverImageMov.imageURL,"type":"video/mov"}}),_c('source',{attrs:{"src":otherTour.coverImage.imageURL,"type":"video/mp4"}}),_c('source',{attrs:{"src":otherTour.coverImage.imageURL,"type":"video/webm"}})]):_c('img',{staticClass:"other-image",attrs:{"src":otherTour.coverImage.imageURL}})])])}),0):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tickets-head-wrap"},[_c('h3',{staticClass:"tickets-head"},[_vm._v("Purchase tickets")]),_c('p',[_vm._v("Check ticket availibility below to purchase a ticket")])])
}]

export { render, staticRenderFns }