<template>
  <div class="lds-ring">
    <div
      :style="{
        'border-color': `${color} transparent transparent transparent`,
      }"
    ></div>
    <div
      :style="{
        'border-color': `${color} transparent transparent transparent`,
      }"
    ></div>
    <div
      :style="{
        'border-color': `${color} transparent transparent transparent`,
      }"
    ></div>
    <div
      :style="{
        'border-color': `${color} transparent transparent transparent`,
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "loader",
  props: {
    size: {
      type: Number,
      default: () => 80,
    },
    color: {
      type: String,
      default: () => "white",
    },
  },
};
</script>

<style>
.lds-ring {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  /* center div */
  align-items: center;
  justify-content: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid;
  border-radius: 50%;
  animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.28s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.18s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.1s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
