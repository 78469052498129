<template>
  <div class="footer" :class="{ hide: shouldHide }">
    <a href="" class="footer-logo"
      ><img src="../assets/logo-footer.svg" height="" width="" /></a
    ><a
      title="facebook"
      href="https://www.facebook.com/yaturutourisrael/"
      target="_blank"
      class="soc-icon-wrap"
      ><img src="../assets/facebook.svg" height="" width="" /></a
    ><a title="mail" href="mailto:info@yaturu.com" class="soc-icon-wrap"
      ><img src="../assets/mail.svg" height="" width="" /></a
    ><a
      title="linkedin"
      href="https://www.linkedin.com/company/yaturu/?originalSubdomain=il"
      target="_blank"
      class="soc-icon-wrap"
      ><img src="../assets/linkedin.svg" height="" width=""
    /></a>
    <div class="footer-links">
      <router-link to="/tos">Terms of service</router-link>
      <p>+972-2-6200950</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-comp",
  computed: {
    shouldHide() {
      let nofooter = ["/checkout"];
      return nofooter.includes(this.$route.path);
    },
  },
};
</script>
