<template>
  <div class="">
    <div class="about-hero">
      <router-link to="/" class="logo-wrap">
        <img src="../assets/logo-white.svg" height="" width="" />
      </router-link>
      <div class="cont-top">
        <a @click="sendAnalytics('click', 'button', 'whatsapp')" class="whatsapp" href="https://wa.me/message/YC3WILNVSVUXN1">
          <img src="../assets/whatsapp.svg" height="" width="" />
          Start Chat
        </a>
        <a class="cont-phone" href="tel:+972-50-595-2713">+972-50-595-2713</a>
      </div>
      <router-link class="lang" :to="`/he/about`">עברית</router-link>
      <div class="about-hero-wrap">
        <div class="about-hero-txt-wrap">
          <h1 class="about-head">Ancient Israel Brought to Life!</h1>
          <p class="about-sub">
            Our host will guide you on your virtual augmented journey as you
            walk the locations of Biblical dramas. Witness the saga through 4000
            years at the City of David and Ancient Jerusalem. Discover Israel’s
            history, legends and characters like never before!
          </p>
        </div>
      </div>
      <img class="king" src="../assets/vr-boy.png" alt="vr" />
      <img class="about-bg" src="../assets/bgw.jpg" />
    </div>
    <carousel class="about-tours-wrap" v-if="tours.length" :nav="false" :autoplay="true" :responsive="{
      0: { items: 1 },
      800: { items: Math.min(3, tours.length) },
    }">
      <router-link v-for="tour in tours" :key="tour._id" :to="`/en/${tour._id}`" class="about-tour-link" href="">
        <div class="about-tour-link-txt-box">
          <p class="about-tour-link-head">{{ tour.nameEnglish }}</p>
          <!-- <p v-html="tour.descriptionEnglish" class="about-tour-link-txt"></p> -->
          <!-- <div class="fbtn">Purchase Tickets</div> -->
          <p>Purchase Tickets</p>
        </div>
        <video style="pointer-events: none" playsinline autoplay mute loop v-if="tour.coverImage.extension === '.mp4'">
          <source :src="tour.coverImage.imageURL" type="video/mp4" />
        </video>
        <img v-else :src="tour.coverImage.imageURL" />
      </router-link>
    </carousel>

    <h2 class="about-mid-head">
      “Amazing 3D sound and vision!”
      <br />“Spectacular Jerusalem walking tour”
    </h2>
    <div class="about-video-wrap">
      <div class="video">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/3f1_fCbtyng?controls=0" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
      </div>
    </div>
    <div class="about-testimonials-wrap">
      <div class="about-testimonial">
        <p>
          "Unlike any other tour out there. Yaturu’s stunning animation not only
          rebuilds the ancient sites before your eyes, but brings you into the
          personal drama of the biblical heroes."
        </p>
        <div class="about-stars-wrap">
          <img class="about-star" src="../assets/Star.svg" />
          <img class="about-star" src="../assets/Star.svg" />
          <img class="about-star" src="../assets/Star.svg" />
          <img class="about-star" src="../assets/Star.svg" />
        </div>
        <p class="about-name">Judy Maynard</p>
        <p class="about-loc">Syndey, Australia</p>
      </div>

      <div class="about-testimonial">
        <p>
          "Fascinating mix of history, archeology and storytelling. Thrilling to
          see the old stories comes to life! Great for the whole family. "
        </p>
        <div class="about-stars-wrap">
          <img class="about-star" src="../assets/Star.svg" />
          <img class="about-star" src="../assets/Star.svg" />
          <img class="about-star" src="../assets/Star.svg" />
          <img class="about-star" src="../assets/Star.svg" />
          <img class="about-star" src="../assets/Star.svg" />
        </div>
        <p class="about-name">Yaron Schwartz</p>
        <p class="about-loc">Rehovot, Israel</p>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
  },
  data() {
    return {
      tours: [],
    };
  },
  methods: {
    sendAnalytics(action, category, label) {
      this.$gtag.event(action, {
        'event_category': category,
        'event_label': label
      });
    },
  },
  async beforeMount() {
    let { data: tours } = await this.$db
      .collection("tours")
      .query("draft", "!=", true)
      .get();
    this.tours = tours;
  },
};
</script>
