<template>
  <div class="he">
    <popup width="90%" v-model="popupVideo">
      <div class="video" v-html="tour.videoLink"></div>
    </popup>
    <div class="hero">
      <router-link to="/he/about" class="logo-wrap">
        <img src="../assets/logo-white.svg" height width />
      </router-link>
      <div class="cont-top">
        <a
          @click="sendAnalytics('click', 'button', 'whatsapp')"
          class="whatsapp"
          href="https://wa.me/message/YC3WILNVSVUXN1"
        >
          <img src="../assets/whatsapp.svg" height width />
          דברו איתנו
        </a>
        <a class="cont-phone white" href="tel:+972-50-595-2713">050-595-2713</a>
      </div>
      <router-link class="lang" :to="`/en/${$route.params.id}`"
        >English</router-link
      >

      <div class="dark-top">
        <div class="hero-txt-box">
          <h1 class="main-head">{{ tour.nameHebrew }}</h1>
          <!-- <a href="#tickets" class="book">הזמינו כרטיסים עכשיו!</a> -->
          <p
            @click="
              goto('#tickets');
              sendAnalytics('click', 'button', 'booknow');
            "
            class="book"
          >
            הזמינו כרטיסים עכשיו!
          </p>

          <div class="playbtn" @click="popupVideo = true"></div>
        </div>
      </div>

      <div class="main-image-wrap" v-if="tour.coverImage">
        <video
          style="pointer-events: none"
          v-if="(tour.coverImage || {}).extension === '.mp4'"
          class="main-image"
          autoplay
          loop
          muted
          playsinline
          :poster="tour.fallback.imageURL"
          preload="metadata"
        >
          <source :src="tour.coverImageMov.imageURL" type="video/mp4" />
          <source :src="tour.coverImageMov.imageURL" type="video/mov" />
          <source :src="tour.coverImage.imageURL" type="video/mp4" />
          <source :src="tour.coverImage.imageURL" type="video/webm" />
        </video>
        <img v-else :src="tour.coverImage.imageURL" class="main-image" />
      </div>
    </div>
    <div class="info-box">
      <div class="left-info">
        <div v-html="tour.descriptionHebrew"></div>
        <!-- <a href="#tickets" class="book">הזמינו עכשיו!</a> -->
        <p
          @click="
            goto('#tickets');
            sendAnalytics('click', 'button', 'booknow');
          "
          class="book"
        >
          הזמינו עכשיו!
        </p>
      </div>

      <div class="right-info">
        <div class="points-wrap">
          <div
            v-for="point in tour.bulletPoint"
            :key="point._id"
            class="info-list-item"
          >
            <div class="info-list-icon-wrap">
              <img
                v-if="point.icon[0].item.icon.imageURL"
                :src="point.icon[0].item.icon.imageURL"
              />
            </div>
            <div class="list-txt">
              <p>
                {{ point.lightTextHebrew }}
                <span class="bold">{{ point.boldTextHebrew }}</span>
              </p>
              <div
                class="list-txt-info-box"
                v-if="point.listHebrew"
                v-html="point.listHebrew"
              ></div>
            </div>
          </div>

          <div class="side-line"></div>
          <p @click="goto('#otherExperiences')" class="bluebtn">עוד חוויות</p>
        </div>
      </div>
    </div>
    <div class="gallery">
      <popup width="90%" v-model="popupImage">
        <img :src="popupImage" />
      </popup>
      <div
        class="gal-image"
        @click="popupImage = image.imageURL"
        v-for="image in tour.imageGallery"
        :key="image.imageURL"
      >
        <img :src="image.imageURL" height width class="gallery-img" />
      </div>
    </div>
    <div id="tickets" class="tickets">
      <div class="tickets-wrap">
        <div class="tickets-head-wrap">
          <h3 class="tickets-head">רכישת כרטיסים</h3>
          <p>מעירים את העבר סיור VR מיוחד</p>
          <div class="steps-wrap hide">
            <div class="step-line"></div>
            <div class="step-dot-wrap">
              <div class="step-dot active">
                <p>01</p>
              </div>
              <p>תאריך</p>
            </div>
            <div class="step-dot-wrap">
              <div class="step-dot">
                <p>02</p>
              </div>
              <p>כרטיסים</p>
            </div>
            <div class="step-dot-wrap">
              <div class="step-dot">
                <p>03</p>
              </div>
              <p>תשלום</p>
            </div>
          </div>
        </div>
        <!--  class="tickets-box" -->
        <div>
          <router-view :productID="tour.checkfrontProductID" :language="'he'" />
        </div>
        <div class="tickets-box hide">
          <div class="ticket-row top">
            <div class="row-part">
              <p>תאריך</p>
            </div>
            <div class="row-part">
              <p>שעה</p>
            </div>
            <div class="row-part">
              <p>מקומות</p>
            </div>
            <div class="row-part">
              <p></p>
            </div>
          </div>
          <div class="ticket-row">
            <div class="row-part">
              <p>20 Jul 2022</p>
            </div>
            <div class="row-part">
              <p>9 am</p>
            </div>
            <div class="row-part">
              <p>20</p>
            </div>
            <div class="row-part">
              <div class="bgl-checkbox-wrap">
                <input
                  class="bgl-checkbox"
                  type="checkbox"
                  id="bglcheckbox"
                  name="bglcheckbox"
                />
                <label class="choose-btn" for="bglcheckbox">בחירה</label>
              </div>
            </div>
          </div>
          <div class="ticket-row">
            <div class="row-part">
              <p>20 Jul 2022</p>
            </div>
            <div class="row-part">
              <p>9 am</p>
            </div>
            <div class="row-part">
              <p>20</p>
            </div>
            <div class="row-part">
              <div class="bgl-checkbox-wrap">
                <div class="bgl-checkbox-wrap">
                  <input
                    class="bgl-checkbox"
                    type="checkbox"
                    id="bglcheckbox"
                    name="bglcheckbox"
                  />
                  <label class="choose-btn" for="bglcheckbox">בחירה</label>
                </div>
              </div>
            </div>
          </div>
          <div class="ticket-row">
            <div class="row-part">
              <p>20 Jul 2022</p>
            </div>
            <div class="row-part">
              <p>9 am</p>
            </div>
            <div class="row-part">
              <p>20</p>
            </div>
            <div class="row-part">
              <div class="bgl-checkbox-wrap">
                <div class="bgl-checkbox-wrap">
                  <input
                    class="bgl-checkbox"
                    type="checkbox"
                    id="bglcheckbox"
                    name="bglcheckbox"
                  />
                  <label class="choose-btn" for="bglcheckbox">בחירה</label>
                </div>
              </div>
            </div>
          </div>
          <div class="ticket-row">
            <div class="row-part">
              <p>20 Jul 2022</p>
            </div>
            <div class="row-part">
              <p>9 am</p>
            </div>
            <div class="row-part">
              <p>20</p>
            </div>
            <div class="row-part">
              <div class="bgl-checkbox-wrap">
                <div class="bgl-checkbox-wrap">
                  <input
                    class="bgl-checkbox"
                    type="checkbox"
                    id="bglcheckbox"
                    name="bglcheckbox"
                  />
                  <label class="choose-btn" for="bglcheckbox">בחירה</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tickets-wrap hide">
        <div class="tickets-head-wrap">
          <h3 class="tickets-head">רכישת כרטיסים</h3>
          <p>מעירים את העיר עם סיור VR מיוחד</p>
          <div class="steps-wrap">
            <div class="step-line"></div>
            <div class="step-dot-wrap">
              <div class="step-dot active">
                <p>01</p>
              </div>
              <p>תאריך</p>
            </div>
            <div class="step-dot-wrap">
              <div class="step-dot active">
                <p>02</p>
              </div>
              <p>כרטיסים</p>
            </div>
            <div class="step-dot-wrap">
              <div class="step-dot">
                <p>03</p>
              </div>
              <p>תשלום</p>
            </div>
          </div>
        </div>
        <div class="tickets-box">
          <div class="ticket-row top">
            <div class="row-part">
              <p>סוג כרטיס</p>
            </div>
            <div class="row-part">
              <p>מחיר</p>
            </div>
            <div class="row-part">
              <p>כמות</p>
            </div>
            <div class="row-part">
              <p>Total</p>
            </div>
          </div>
          <div class="ticket-row">
            <div class="row-part">
              <p>אזרח ותיק</p>
            </div>
            <div class="row-part">
              <p>50 ש״ח</p>
            </div>
            <div class="row-part">
              <input placeholder="0" type="number" class="num-tickets" />
            </div>
            <div class="row-part">
              <p>20</p>
            </div>
          </div>
          <div class="ticket-row">
            <div class="row-part">
              <p>מבוגר</p>
            </div>
            <div class="row-part">
              <p>50 ש״ח</p>
            </div>
            <div class="row-part">
              <input placeholder="0" type="number" class="num-tickets" />
            </div>
            <div class="row-part">
              <p>20</p>
            </div>
          </div>
          <div class="ticket-row">
            <div class="row-part">
              <p>ילד</p>
            </div>
            <div class="row-part">
              <p>50 ש״ח</p>
            </div>
            <div class="row-part">
              <input placeholder="0" type="number" class="num-tickets" />
            </div>
            <div class="row-part">
              <p>20</p>
            </div>
          </div>
          <div class="ticket-row">
            <div class="row-part">
              <p>סטודנט</p>
            </div>
            <div class="row-part">
              <p>50 ש״ח</p>
            </div>
            <div class="row-part">
              <input placeholder="0" type="number" class="num-tickets" />
            </div>
            <div class="row-part">
              <p>20</p>
            </div>
          </div>
          <div class="ticket-row">
            <div class="row-part">
              <p>אחר</p>
            </div>
            <div class="row-part">
              <p>50 ש״ח</p>
            </div>
            <div class="row-part">
              <input placeholder="0" type="number" class="num-tickets" />
            </div>
            <div class="row-part">
              <p>20</p>
            </div>
          </div>
          <div class="ticket-row last">
            <div class="row-part">
              <p>סך הכל</p>
            </div>
            <div class="row-part"></div>
            <div class="row-part"></div>
            <div class="row-part">
              <p>200 NIS</p>
            </div>
          </div>
        </div>
        <button type="button" class="next back">חזרה</button
        ><button type="button" class="next">הבא</button>
      </div>
      <div class="tickets-wrap hide">
        <div class="tickets-head-wrap">
          <h3 class="tickets-head">רכישת כרטיסים</h3>
          <p>מעירים את העבר סיור VIRTUAL REALITY מיוחד במרכז דוידסון</p>
          <div class="steps-wrap">
            <div class="step-line"></div>
            <div class="step-dot-wrap">
              <div class="step-dot active">
                <p>01</p>
              </div>
              <p>תאריך</p>
            </div>
            <div class="step-dot-wrap">
              <div class="step-dot active">
                <p>02</p>
              </div>
              <p>כרטיסים</p>
            </div>
            <div class="step-dot-wrap">
              <div class="step-dot active">
                <p>03</p>
              </div>
              <p>תשלום</p>
            </div>
          </div>
        </div>
        <div class="tickets-box">
          <div class="ticket-row top center">
            <div class="row-part">
              <p>תשלום</p>
            </div>
          </div>
          <form action="/" class="payment">
            <input
              placeholder="שם"
              type="text"
              id
              name
              class="text-input"
            /><input
              placeholder="דוא״ל"
              type="email"
              id
              name
              class="text-input"
            /><input
              placeholder="טלפון"
              type="text"
              id
              name
              class="text-input"
            /><input
              placeholder="כרטיס אשראי"
              type="text"
              id
              name
              class="text-input"
            /><input type="submit" id value="תשלום" class="book" />
          </form>
        </div>
        <button type="button" class="next back">חזרה</button>
      </div>
    </div>
    <div id="otherExperiences">
      <h3 class="tickets-head other">חוויות נוספות שלנו</h3>
      <carousel
        v-if="otherTours.length"
        class="other-tours"
        :nav="false"
        :loop="true"
        :autoplay="true"
        :autoplayTimeout="7000"
        :margin="30"
        :responsive="{
          0: { items: 1 },
          800: { items: Math.min(3, otherTours.length) },
        }"
      >
        <div
          class="other-tour-link-wrap"
          v-for="otherTour in otherTours"
          :key="otherTour._id"
        >
          <a class="other-tour" :href="'/he/' + otherTour._id">
            <div class="about-tour-link-txt-box">
              <p>{{ otherTour.nameHebrew }}</p>
              <!-- <div class="small-fbtn">לרכישת כרטיסים</div> -->
              <p>לרכישת כרטיסים</p>
            </div>
            <video
              playsinline
              style="pointer-events: none"
              autoplay
              muted
              loop
              class="other-image"
              :poster="otherTour.fallback.imageURL"
              v-if="(otherTour.coverImage || {}).extension === '.mp4'"
            >
              <source
                :src="otherTour.coverImageMov.imageURL"
                type="video/mp4"
              />
              <source
                :src="otherTour.coverImageMov.imageURL"
                type="video/mov"
              />
              <source :src="otherTour.coverImage.imageURL" type="video/mp4" />
              <source :src="otherTour.coverImage.imageURL" type="video/webm" />
            </video>
            <img
              v-else
              :src="otherTour.coverImage.imageURL"
              class="other-image"
            />
          </a>
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
// import Checkfront from "@/components/CheckFront";
import carousel from "vue-owl-carousel";

export default {
  components: {
    // Checkfront,
    carousel,
  },
  data() {
    return {
      popupImage: "",
      popupVideo: "",
      tour: { descriptionHebrew: "סיור מיוחד" },
      otherTours: [],
    };
  },
  methods: {
    goto(refName) {
      const isID = !!refName.match(/^#/);
      var element;
      if (isID) element = document.querySelector(refName);
      else element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo({ left: 0, top, behavior: "smooth" });
    },
    loadStartVideo(video) {
      const isVideoPlaying = (video) =>
        !!(
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > 2
        );
      if (isVideoPlaying) {
        document.addEventListener("click", () => {
          video.target.play();
        });
      }
    },
    sendAnalytics(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },
  },
  async beforeMount() {
    let { id: currentTourID } = this.$route.params;
    let { data } = await this.$db
      .collection("tours")
      // ? this is a fallback
      .item(currentTourID != ":id" ? currentTourID : "620b748352951ebd286bd024")
      .everything()
      .get();

    let { data: otherTours } = await this.$db
      .collection("tours")
      .query("_id", "!=", currentTourID)
      .query("draft", "!=", true)
      .get();

    this.otherTours = otherTours;
    this.tour = data;
  },
  name: "He",
};
</script>
