import ProjectEnglish from "../views/ProjectEnglish.vue";
import ProjectHebrew from "../views/ProjectHebrew.vue";
import AboutEnglish from "../views/AboutEnglish.vue";
import AboutHebrew from "../views/AboutHebrew.vue";
import Checkout from "../views/Checkout.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import Success from "../views/Success.vue";
import Cancel from "../views/Cancel.vue";
import TermsOfService from "../views/TermsOfService.vue";
import BookingPage from "../views/BookingPage.vue";
import Checkfront from "../components/CheckFront";
// import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: AboutEnglish,
  },
  {
    path: "/en/about",
    name: "About English",
    component: AboutEnglish,
  },
  {
    path: "/en/:id",
    name: "Project English",
    component: ProjectEnglish,
    children: [
      {
        path: "",
        component: Checkfront,
      },
    ],
  },
  {
    path: "/he/about",
    name: "About Hebrew",
    component: AboutHebrew,
  },
  {
    path: "/he/:id",
    name: "Project Hebrew",
    component: ProjectHebrew,
    children: [
      {
        path: "",
        component: Checkfront,
      },
    ],
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
  },
  {
    path: "/success",
    name: "Success",
    component: Success,
  },
  {
    path: "/cancel",
    name: "Cancel",
    component: Cancel,
  },
  {
    path: "/tos",
    name: "Terms Of Service",
    component: TermsOfService,
  },
  {
    path: "/BookingPage",
    name: "Booking Page",
    component: BookingPage,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
