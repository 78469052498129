<template>
  <div class="he">
    <div class="about-hero">
      <router-link to="/he/about" class="logo-wrap">
        <img src="../assets/logo-white.svg" height="" width="" />
      </router-link>
      <div class="cont-top">
        <a @click="sendAnalytics('click', 'button', 'whatsapp')" class="whatsapp" href="https://wa.me/message/YC3WILNVSVUXN1">
          <img src="../assets/whatsapp.svg" height="" width="" />
          דברו איתנו
        </a>
        <a class="cont-phone" href="tel:+972-50-595-2713">050-595-2713</a>
      </div>
      <router-link class="lang" :to="`/en/about`">English</router-link>
      <div class="about-hero-wrap">
        <div class="about-hero-txt-wrap">
          <h1 class="about-head">סיורי VR חוויה לכל המשפחה!</h1>
          <p class="about-sub">
            בואו להיות חלק ממסע בזמן ולגלות את ירושלים במציאות מדומה. הסיפורים
            ההיסטוריים והדרמות הגדולות כפי שמעולם לא ראיתם!סיורים במספר אתרים :
            עיר דוד , מרכז דוידסון , קניון ים המלח ומתחם התחנה בירושלים חוויה
            לכל המשפחה המועבר בטכנולוגיה חדשנית, באמצעות משקפי מציאות מדומה
            והדרכה וירטואלית, המעניקים צבע, קול ותחושה לסיפורים של ירושלים.
          </p>
        </div>
      </div>
      <img class="king" src="../assets/vr-boy.png" alt="vr" />
      <img class="about-bg" src="../assets/bgw.jpg" />
    </div>
    <p></p>
    <carousel class="about-tours-wrap" v-if="tours.length" :nav="false" :autoplay="true" :autoplayTimeout="7000" :responsive="{
      0: { items: 1 },
      800: { items: Math.min(3, tours.length) },
    }">
      <router-link v-for="tour in tours" :key="tour._id" :to="`/he/${tour._id}`" class="about-tour-link" href="">
        <div class="about-tour-link-txt-box">
          <p class="about-tour-link-head">{{ tour.nameHebrew }}</p>
          <!-- <p v-html="tour.descriptionHebrew" class="about-tour-link-txt"></p> -->
          <!-- <div class="fbtn">לרכישת כרטיסים</div> -->
          <p>לרכישת כרטיסים</p>
        </div>
        <video style="pointer-events: none" playsinline autoplay mute loop :poster="tour.fallback.imageURL" preload="metadata" v-if="(tour.coverImage || {}).extension === '.mp4'">
          <source :src="tour.coverImageMov.imageURL" type="video/mp4" />
          <source :src="tour.coverImageMov.imageURL" type="video/mov" />
          <source :src="tour.coverImage.imageURL" type="video/mp4" />
          <source :src="tour.coverImage.imageURL" type="video/webm" />
        </video>
        <img v-else :src="tour.coverImage.imageURL" />
      </router-link>
    </carousel>

    <h2 class="about-mid-head">
      "סיור רגלי מרהיב בירושלים"
      <br />
      "מראה ושמע תלת ממדי מדהימים"
    </h2>
    <div class="about-video-wrap">
      <div class="video">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/3f1_fCbtyng?controls=0" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
      </div>
    </div>
    <div class="about-testimonials-wrap">
      <div class="about-testimonial">
        <p>
          לא דומה לשום סיור אחר שם בחוץ. האנימציות המדהימות לא רק בונות מחדש את
          האתר העתיק לנגד עיניך, אלא גם מחזירות אותך בזמן בחזרה אל הגיבורים
          והסיפורים התנ"כיים.
        </p>
        <div class="about-stars-wrap">
          <img class="about-star" src="../assets/Star.svg" />
          <img class="about-star" src="../assets/Star.svg" />
          <img class="about-star" src="../assets/Star.svg" />
          <img class="about-star" src="../assets/Star.svg" />
        </div>
        <p class="about-name">ג'ודי מינרד</p>
        <p class="about-loc">סידני, אוסטרליה</p>
      </div>

      <div class="about-testimonial">
        <p>
          שילוב מרתק של היסטוריה, ארכיאולוגיה ודרמה. מרגש לראות את הסיפורים
          הישנים מתעוררים לחיים
        </p>
        <div class="about-stars-wrap">
          <img class="about-star" src="../assets/Star.svg" />
          <img class="about-star" src="../assets/Star.svg" />
          <img class="about-star" src="../assets/Star.svg" />
          <img class="about-star" src="../assets/Star.svg" />
          <img class="about-star" src="../assets/Star.svg" />
        </div>
        <p class="about-name">ירון שוורץ</p>
        <p class="about-loc">רחובות</p>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
  },
  data() {
    return {
      tours: [],
      carousel,
    };
  },
  methods: {
    sendAnalytics(action, category, label) {
      this.$gtag.event(action, {
        'event_category': category,
        'event_label': label
      });
    },
  },
  async beforeMount() {
    let { data: tours } = await this.$db
      .collection("tours")
      .query("draft", "!=", true)
      .get();
    this.tours = tours;
  },
};
</script>
