
// import loader from "./loader.vue";
import moment from "moment";
import axios from "axios";
import { availableDates, successfulBooking } from "@/assets/mockData";
import { defineComponent } from "@vue/composition-api";
import CheckFrontForm from "@/components/CheckFrontForm";
import PayPalButton from "@/components/PayPalButton.vue";
import Checkout from "../../views/Checkout.vue";
import env from "../../../env";
import "./style.css";
import Loader from "../loader.vue";
import NumToggle from "../NumToggle.vue";
import StatusBox from "../StatusBox.vue";
import Success from "@/views/Success.vue";

// window.moment = moment;
let alternateRowCount = 0;
const tourIDs = [
  "620b748352951ebd286bd024" /* ga4_03_21_city_of_david_book_now */,
  "621652696d7264b544d4d0d9" /* ga4_03_22_ancient_israel_book_now */,
  "621652df6d7264b544d4d0da" /* ga4_03_21_davidson_center_book_now */,
  "6252701f9594069a44bf370b",
  "625448d93baaeaebfc9e7d5a",
  "62f0a2e140f0fbb1759f223c",
];

const mapStatuses = () => ({
  noAvailableTickets: false,
  showTicketHelp: false,
  showPaymentHelp: false,
  showPaymentError: false,
  isPaypalSuccess: false,
});
const initialForm = () => ({
  customer_name: "",
  customer_email: "",
  countryCode: "",
  customer_phone: "",
  address: "",
  customer_city: "",
  state: "",
  zip: "",
  customer_country: "",
  note: "",
  booking_language: "en",
});

export default defineComponent({
  components: {
    CheckFrontForm,
    Checkout,
    PayPalButton,
    Loader,
    NumToggle,
    StatusBox,
    Success,
  },
  name: "checkfront",
  props: {
    productID: String,
    language: String,
  },
  data() {
    const now = moment().format("YYYY-MM-DD");
    return {
      // ticketForm: null,
      discount_code: "",
      productData: {} as Record<string, any>,
      isFetching: false,
      modelConfig: {
        mask: { input: "YYYY-MM-DD" },
        type: "string",
      },
      minDate: now,
      moment,
      range: {
        start: now,
        end: moment().add(3, "days").format("YYYY-MM-DD"),
      },
      currentStep: 0,
      stepContent: [
        null as unknown as typeof availableDates,
        {
          columns: [
            this.$t("Ticket Type"),
            this.$t("Price"),
            this.$t("Amount"),
            this.$t("Total"),
          ],
          rows: null as unknown as typeof availableDates.item.rate.event[0],
        },
        null as unknown as Record<string, any>,
      ],
      selectedEvent: null as unknown as typeof availableDates.item &
        Record<string, any>,
      tempTickets: {
        child: { count: 0 },
        adult: { count: 0 },
        qty: { count: 0 },
      },
      tickets: {} as {
        [key: string]: {
          count: number;
          price: number;
        };
      },
      maxTickets: 7,
      // availableDates,
      form: initialForm(),
      paymentMethod: "" as "paypal" | "credit" | "bit" | "",
      successfulBooking: null as unknown as typeof successfulBooking,
      productionKey: "",
      paypalTotal: 0 as string | number,
      booking_id: "" as string | number,
      paymentObj: {} as Record<string, any>,
      maxTicketAlert: "",
      ...mapStatuses(),
    };
  },
  watch: {
    async productID(newVal) {
      try {
        if (!newVal) return;
        const { start, end } = this.range;
        await this.getAvailableDates(start, end);
      } catch (error) {
        console.log(error);
      }
    },
    async range(newVal) {
      try {
        const { start, end } = newVal;
        this.sendAnalyticsEvent("change", "date", "");
        await this.getAvailableDates(start, end);
        this.currentStep = 0;
      } catch (error) {
        console.log(error);
      }
    },
    stepContent: {
      async handler(newVal) {
        if (!newVal[0]) {
          const { start, end } = newVal;
          await this.getAvailableDates(start, end);
        }
      },
      deep: true,
    },
    currentStep(newVal) {
      if (newVal === 1) {
        window.onbeforeunload = async (e: BeforeUnloadEvent) => {
          e.preventDefault();
          await this.cancelBooking();
          this.currentStep = 0;
          await this.getAvailableDates();
          return (e.returnValue = "");
        };
      }
    },
    "form.customer_phone": {
      handler() {
        this.showPaymentHelp = false;
      },
      deep: true,
    },
  },
  computed: {
    isSubmit(): boolean {
      return !(this.currentStep < 2);
    },
    getMaxTickets(): number {
      const [adult, child, qty] = Object.values(this.tickets);
      const { available } = this.selectedEvent.rate;
      const max =
        available -
        (adult?.count || 0) -
        (child?.count || 0) -
        (qty?.count || 0);
      return max;
    },
    showComputedDiscount(): boolean {
      return (
        this?.selectedEvent?.discount &&
        this.currentStep >= 2 &&
        this.paypalTotal
      );
    },
    computedDiscount(): number | undefined {
      if (this.showComputedDiscount) {
        return Number(this.paypalTotal);
      }
      return this.getTotal;
    },
    getTotal(): number {
      const [adult, child, qty] = Object.values(this.tickets);
      const total =
        (adult?.count * adult?.price || 0) +
        (child?.count * child?.price || 0) +
        (qty?.count * qty?.price || 0);
      return total;
    },
    isPaypal(): boolean {
      return !!this.booking_id && this.paymentMethod === "paypal";
    },
  },
  async mounted() {
    this.productionKey = env.VUE_APP_PAYPAL_TOKEN;
    // this.ticketForm = document.querySelector("#ticketForm");
    // var s = document.createElement("script");
    // s.type = "text/javascript";
    // s.src = "//yaturu.checkfront.com/lib/interface--0.js";
    // let head = document.querySelector("head");
    // head.append(s);
    // setTimeout(() => this.newDroplet(), 1500);
    alternateRowCount = 0;
    this.start = moment(this.minDate).format("YYYY-MM-DD");
    this.end = moment(this.minDate).add(2, "days").format("YYYY-MM-DD");
  },
  async beforeRouteLeave(_, __, next) {
    window.onbeforeunload = null;
    if (!(this.currentStep > 0) && !this.booking_id && !this.discount_code) {
      return next();
    }
    const answer = window.confirm(
      "Do you really want to leave? you have might have unsaved changes!"
    );
    if (answer) {
      await this.cancelBooking();
      next();
    } else {
      next(false);
    }
  },
  methods: {
    conversionName() {
      const { id } = this.$route.params;
      const res =
        id === tourIDs[0]
          ? "ga4_03_21_city_of_david_book_now"
          : id === tourIDs[1]
          ? "ga4_03_22_ancient_israel_book_now"
          : id === tourIDs[2]
          ? "ga4_03_21_davidson_center_book_now"
          : id;
      return res;
    },
    handleStepForward() {
      this.showTicketHelp = this.currentStep === 1 && !this.getTotal;
      if (this.showTicketHelp) return;
      this.currentStep++;
    },
    async handleStepBack() {
      if (this.isPaypalSuccess) return this.$router.push("/");
      await this.cancelBooking();
      this.paypalTotal = 0;
      // if (this.paymentMethod) {
      this.paymentMethod = "";
      this.booking_id = "";
      this.paymentObj = {};
      // }
      this.currentStep--;
    },
    handleNumInput($e: InputEvent, key: string | number, price: number) {
      const value = $e;
      // console.log({ $e: value, key, price });
      this.$set(this.tickets, key, { count: value, price });
    },
    handleChoice({ item, date, row, index }: any) {
      const slip = item.rate.slip;

      const selectedEvent = item.rate.event[0];
      this.selectedEvent = { ...item, slip, date, row, index, selectedEvent };
      this.stepContent[1].rows = selectedEvent;
      console.log("handleChoice", { item, date, row, index, slip });
      this.currentStep = 1;
    },
    alternateRow(): boolean {
      return alternateRowCount++ % 2 === 0;
    },
    getDate(seconds: number | string) {
      // use "seconds" argument convert to milliseconds to get the date and return formated date
      return moment.unix(+seconds + +"000").format("LL");
    },
    getThreeDays(date: string) {
      // get the next three days from the selected date
      return moment(date).add(3, "days").format("YYYY-MM-DD");
    },
    scrollToEl() {
      this.$nextTick(() => {
        const el = this.$refs.ticketForm as HTMLElement;
        el?.scrollIntoView({ block: "center", behavior: "smooth" });
      });
    },
    newDroplet() {
      // eslint-disable-next-line no-undef
      // new DROPLET.Widget({
      //   host: "yaturu.checkfront.com",
      //   target: "CHECKFRONT_WIDGET_01",
      //   item_id: this.productID,
      //   lang_id: this.language,
      //   locale_id: `${this.language}_IL`,
      //   options: "category_select",
      //   style: "font-family: Assistant;",
      //   provider: "droplet",
      // }).render();
    },
    async getAvailableDates(startDate?: string, endDate?: string) {
      this.isFetching = true;
      const {
        range: { start, end },
        discount_code,
      } = this;
      // fetches available dates for the next 3 days
      const _startDate = moment(startDate || start).format("YYYY-MM-DD");
      const _endDate = moment(endDate || end).format("YYYY-MM-DD");
      const { data: itemData } =
        // { data: { checkfrontProductID: this.productID } } ||
        await this.$db
          .collection("tours")
          .item(this.$route.params.id)
          .projectOn("nameEnglish,checkfrontProductID")
          .get();
      this.productData = itemData;
      const item_id = itemData?.checkfrontProductID;
      const { data }: { data: typeof availableDates } = await axios.get(
        `/api/useCheckFront?start_date=${_startDate}&end_date=${_endDate}&item_id=${
          item_id || ""
        }&lang_id=${
          this.language
        }&discount_code=${discount_code}&provider=droplet`
      );
      //   const _availableDates =  data
      this.stepContent[0] = data;
      this.isFetching = false;

      this.noAvailableTickets = data?.item?.rate?.available === null;

      this.runTest();
      this.$forceUpdate();
    },
    async handleSubmit() {
      this.isFetching = true;
      this.showPaymentError = false;
      this.showPaymentHelp =
        !`${this.form.countryCode}${this.form.customer_phone}`.match(
          /\+?(?:0|972)5\d{1}-?\d{3}-?\d{4}/
        ) && this.paymentMethod !== "paypal";
      if (this.showPaymentHelp) return;

      const {
        tickets: { adult, child, qty },
        selectedEvent: { row, slip },
      } = this;
      const match = [/@.{5}/g, /adult\.\d*/g, /child\.\d*/g, /qty\.\d*/g];
      const replace = [
        row.start_time,
        `adult.${adult?.count || 0}`,
        `child.${child?.count || 0}`,
        `qty.${qty?.count || 0}`,
      ];

      const _slip = slip
        .replace(match[0], `@${replace[0]}`)
        .replace(match[1], `${replace[1]}`)
        .replace(match[2], `${replace[2]}`)
        .replace(match[3], `${replace[3]}`);

      this.sendAnalyticsEvent("click", "pay", this.paymentMethod); //track payment button click event

      try {
        const payload = {
          form: {
            ...this.form,
            customer_phone: `${this.form.countryCode}${this.form.customer_phone}`,
          },
          slip: _slip,
        } as Record<string, any>;
        // iterate over payload and create new associative array for the payload for checkfront api with eachproperty as key prefixed by [form]
        Object.keys(payload.form).forEach((key) => {
          payload[`form[${key}]`] = payload.form[key];
          delete payload.form[key];
        });

        delete payload.form.countryCode;
        delete payload.form;

        const queryParams = new URLSearchParams(payload);
        console.log({ queryParams: queryParams.toString(), payload });

        const { data } = await axios.get(
          `/api/useCheckFront?${queryParams.toString()}`
        );
        // console.log("submitForm", { payload, data });
        this.successfulBooking = data;
        this.paypalTotal = data.booking.sub_total;
        this.booking_id = data.booking.booking_id;
        if (this.paymentMethod === "credit") await this.getCreditPayment();
        if (this.paymentMethod === "bit") await this.getMeshulamPayment();
        this.isFetching = false;
      } catch (error) {
        await this.cancelBooking(error);
      }
    },
    async cancelBooking(error?: any) {
      try {
        this.isFetching = false;
        this.paymentMethod = "";
        this.showPaymentError = !!error;
        this.paymentObj = {};
        this.tickets = {};
        console.warn(error);
        try {
          const { data } = await axios.get(
            `/api/useCheckFront?booking_id=${this.booking_id}&cancel=true`
          );
          console.log({ data });
        } catch (error) {
          if (error) console.warn(error);
        }
        this.booking_id = "";
      } catch (error) {
        console.warn(error);
      }
    },
    async getCreditPayment() {
      try {
        const { booking_id } = this;
        let { data } = await axios.post("/api/createPayment", {
          booking_id,
          paymentType: "creditCard",
        });
        this.paymentObj = data;

        if (!booking_id) return;
        this.sendAnalytics(
          booking_id,
          "creditCard",
          this.successfulBooking.booking.sub_total
        );
      } catch (error) {
        throw new Error(error as any);
      }
    },
    async getMeshulamPayment() {
      try {
        const { booking_id } = this;
        let { data } = await axios.post("/api/createPayment", {
          booking_id,
          paymentType: "bit",
        });
        this.paymentObj = data;

        if (!booking_id) return;
        this.sendAnalytics(
          booking_id,
          "bit",
          this.successfulBooking.booking.sub_total
        );
      } catch (error) {
        throw new Error(error as any);
      }
    },
    async paypalSuccess() {
      try {
        const { booking_id } = this;
        let { data } = await axios.post("/api/paypalSuccess", {
          booking_id,
          set_paid: 1,
        });
        console.log(data);
      } catch (error) {
        throw new Error(error as any);
      }
    },
    sendAnalyticsEvent(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },
    sendAnalytics(transaction_id, affiliation, value, args = {}) {
      var qty = 1;
      if (
        this.successfulBooking &&
        this.successfulBooking.booking &&
        this.successfulBooking.booking.items &&
        this.successfulBooking.booking.items["1"]
      ) {
        qty = 0;
        var param = this.successfulBooking.booking.items["1"].param;
        Object.keys(param).forEach(function (key) {
          qty += param[key].qty;
        });
      }
      if (process.env.NODE_ENV === "development") {
        console.log(process.env.NODE_ENV !== "development", "test");
        if (!this.successfulBooking) return;
        this.$gtag.event("purchase", {
          transaction_id: transaction_id + "_TEST_" + Date.now(),
          affiliation: affiliation,
          value: value,
          currency: "ILS",
          items: [
            {
              // item_id: this.$route.params.id,
              id: this.conversionName(),
              name: this.successfulBooking.booking.items["1"]
                ? this.successfulBooking.booking.items["1"].name
                : this.conversionName(),
              quantity: qty,
              price: value / qty,
            },
          ],
          ...args,
        });
        return;
      }
      this.$gtag.event("purchase", {
        transaction_id: transaction_id,
        affiliation: affiliation,
        value: value,
        currency: "ILS",
        items: [
          {
            id: this.conversionName(),
            name: this.successfulBooking.booking.items["1"]
              ? this.successfulBooking.booking.items["1"].name
              : this.conversionName(),
            quantity: qty,
            price: value / qty,
          },
        ],
        ...args,
      });

      //track fb pixel purchase
      window["fbq"]("track", "Purchase", {
        currency: "ILS",
        value: value,
        content_type: "product",
        content_name: this.successfulBooking.booking.items["1"]
          ? this.successfulBooking.booking.items["1"].name
          : this.$route.params.id,
        contents: [
          {
            id: this.$route.params.id,
            quantity: qty,
          },
        ],
      });

      //track trade desk
      if (typeof window["TTDUniversalPixelApi"] === "function") {
        var universalPixelApi = new window["TTDUniversalPixelApi"]();
        universalPixelApi.init(
          "p934fbq",
          ["wbkj6he"],
          "https://insight.adsrvr.org/track/up",
          {
            orderid: this.successfulBooking.booking.booking_id,
            td1: this.successfulBooking.booking.items["1"]
              ? this.successfulBooking.booking.items["1"].name
              : this.conversionName(), //product name
            td2: qty, //quantity
            td3: value / qty, //unit cost
            v: value, //revenue
            vf: "USD",
          }
        );
      }
    },
    async handleAddDiscount({ triggeredByClick = false }) {
      // if (triggeredByClick) {
      //   this.currentStep = 0;
      // }
      if (!this.discount_code) return;
      const willNeedtoStartOver = this.currentStep > 0;

      if (willNeedtoStartOver) {
        const userConfirm = window.confirm(
          this.$t("confirmDiscountCountSubmit") as string
        );
        if (!userConfirm) return;
        this.currentStep = 0;
      }

      try {
        const { start, end } = this.range;
        await this.getAvailableDates(start, end);
      } catch (error) {
        console.log(error);
      }
      try {
        this.currentStep = 0;
      } catch (error) {
        console.log(error);
      }
    },
    runTest() {
      console.log(process.env.NODE_ENV !== "development", "test");

      if (process.env.NODE_ENV !== "development") return;

      // ! FOR TESTS
      this.sendAnalytics(
        "sendAnalyticsTest" + Date.now(),
        "sendAnalyticsTest" + Date.now(),
        0.1
      );
    },
  },
});
