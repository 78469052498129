<template>
  <div>
    <div v-if="!paymentObj.url" class="wrapper">
      <h2>{{ $t("Select your payment method") }}</h2>
      <label :style="{ color: color }">
        <a
          :style="{ color: color }"
          href="https://yaturutour.com/tos"
          target="_target"
          >לתנאי השימוש</a
        >
        אני מסכים
        <input @change="color = 'black'" type="checkbox" v-model="tosAgree" />
        <!-- I agree to the <a href="https://yaturutour.com/tos" target="_target">Terms of service</a> -->
      </label>
      <span style="font-size: 15px">Payment for Israeli Cards</span>
      <button class="bit payment" @click="getMeshulamPayment">
        Pay with <img height="35" src="../assets/bit.png" />
      </button>
      <button class="payment" @click="getCreditPayment">
        Pay with Credit Card
      </button>
      <hr />
      <span style="font-size: 15px"> For all other countries </span>

      <PayPalButton
        :productionKey="productionKey"
        :amount="total"
        :description="`Yaturu Booking: ${booking_id}`"
        @approve="paypalSuccess"
      />
    </div>
    <iframe v-else :src="paymentObj.url" class="payment-iframe"> </iframe>
  </div>
</template>

<script>
import PayPalButton from "../components/PayPalButton.vue";
import axios from "axios";
import { paymentTypes } from "../../enums";
import env from "../../env";
export default {
  components: { PayPalButton },
  data() {
    return {
      color: "black",
      tosAgree: false,
      booking_id: "",
      total: 0,
      paymentObj: {},
      productionKey: "",
    };
  },
  methods: {
    async getCreditPayment() {
      if (!this.tosAgree) {
        this.color = "red";
        return;
      }
      const { booking_id } = this;
      let { data } = await axios.post("/api/createPayment", {
        booking_id,
        paymentType: paymentTypes.CREDITCARD,
      });
      this.paymentObj = data;
    },
    async getMeshulamPayment() {
      if (!this.tosAgree) {
        this.color = "red";
        return;
      }
      const { booking_id } = this;
      let { data } = await axios.post("/api/createPayment", {
        booking_id,
        paymentType: paymentTypes.BIT,
      });
      this.paymentObj = data;
    },
    async paypalSuccess() {
      const { booking_id } = this;
      let { data } = await axios.post("/api/paypalSuccess", {
        booking_id,
        set_paid: 1,
      });
      console.log(data);
      this.$router.push("/success");
    },
  },
  async beforeMount() {
    this.productionKey = env.VUE_APP_PAYPAL_TOKEN;
    const { booking_id, total } = this.$route.query;
    this.booking_id = booking_id;
    this.total = total;
  },
  name: "checkout",
};
</script>

<style scoped>
.payment-iframe {
  border: none;
  min-height: 500px;
  width: 100%;
}

.payment {
  text-align: center;
  color: white;
  display: block;
  margin: 0 auto 12px;
  max-width: 500px;
  width: 90%;
  border-radius: 100px;
  height: 53px;
  background: rgb(42, 42, 42);
}

.bit {
  background-image: linear-gradient(180deg, #2a5985, #2cb2b9);
  color: white;
}

.wrapper {
  display: flex;
  height: 700px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
