export const contryOptions = [
  ["AF", "Afghanistan"],
  ["AL", "Albania"],
  ["DZ", "Algeria"],
  ["AS", "American Samoa"],
  ["AD", "Andorra"],
  ["AO", "Angola"],
  ["AI", "Anguilla"],
  ["AG", "Antigua And Barbuda"],
  ["AR", "Argentina"],
  ["AM", "Armenia"],
  ["AW", "Aruba"],
  ["AU", "Australia"],
  ["AT", "Austria"],
  ["AZ", "Azerbaijan"],
  ["BS", "Bahamas"],
  ["BH", "Bahrain"],
  ["BD", "Bangladesh"],
  ["BB", "Barbados"],
  ["BY", "Belarus"],
  ["BE", "Belgium"],
  ["BZ", "Belize"],
  ["BJ", "Benin"],
  ["BM", "Bermuda"],
  ["BT", "Bhutan"],
  ["BO", "Bolivia"],
  ["BA", "Bosnia And Herzegovina"],
  ["BW", "Botswana"],
  ["BR", "Brazil"],
  ["BN", "Brunei Darussalam"],
  ["BG", "Bulgaria"],
  ["BF", "Burkina Faso"],
  ["BI", "Burundi"],
  ["KH", "Cambodia"],
  ["CM", "Cameroon"],
  ["CA", "Canada"],
  ["CV", "Cape Verde"],
  ["KY", "Cayman Islands"],
  ["CF", "Central African Republic"],
  ["TD", "Chad"],
  ["CL", "Chile"],
  ["CN", "China"],
  ["MF", "Collectivity of Saint Martin"],
  ["CO", "Colombia"],
  ["KM", "Comoros"],
  ["CD", "Congo (DC)"],
  ["CG", "Congo (R)"],
  ["CK", "Cook Islands"],
  ["CR", "Costa Rica"],
  ["HR", "Croatia"],
  ["CU", "Cuba"],
  ["CW", "Curaçao"],
  ["CY", "Cyprus"],
  ["CZ", "Czech Republic"],
  ["DK", "Denmark"],
  ["DJ", "Djibouti"],
  ["DM", "Dominica"],
  ["DO", "Dominican Republic"],
  ["TP", "East Timor"],
  ["EC", "Ecuador"],
  ["EG", "Egypt"],
  ["SV", "El Salvador"],
  ["GQ", "Equatorial Guinea"],
  ["ER", "Eritrea"],
  ["EE", "Estonia"],
  ["ET", "Ethiopia"],
  ["FK", "Falkland Islands"],
  ["FO", "Faroe Islands"],
  ["FJ", "Fiji"],
  ["FI", "Finland"],
  ["FR", "France"],
  ["PF", "French Polynesia"],
  ["GA", "Gabon"],
  ["GM", "Gambia"],
  ["GE", "Georgia"],
  ["DE", "Germany"],
  ["GH", "Ghana"],
  ["GI", "Gibraltar"],
  ["GR", "Greece"],
  ["GL", "Greenland"],
  ["GD", "Grenada"],
  ["GP", "Guadeloupe"],
  ["GU", "Guam"],
  ["GT", "Guatemala"],
  ["GN", "Guinea"],
  ["GW", "Guinea-bissau"],
  ["GY", "Guyana"],
  ["HT", "Haiti"],
  ["VA", "Holy See"],
  ["HN", "Honduras"],
  ["HK", "Hong Kong"],
  ["HU", "Hungary"],
  ["IS", "Iceland"],
  ["IN", "India"],
  ["ID", "Indonesia"],
  ["IR", "Iran"],
  ["IQ", "Iraq"],
  ["IE", "Ireland"],
  ["IL", "Israel"],
  ["IT", "Italy"],
  ["CI", "Ivory coast"],
  ["JM", "Jamaica"],
  ["JP", "Japan"],
  ["JO", "Jordan"],
  ["KZ", "Kazakhstan"],
  ["KE", "Kenya"],
  ["KI", "Kiribati"],
  ["KW", "Kuwait"],
  ["KG", "Kyrgyzstan"],
  ["LA", "Laos"],
  ["LV", "Latvia"],
  ["LB", "Lebanon"],
  ["LS", "Lesotho"],
  ["LR", "Liberia"],
  ["LI", "Liechtenstein"],
  ["LT", "Lithuania"],
  ["LU", "Luxembourg"],
  ["LY", "Lybia"],
  ["MO", "Macao"],
  ["MK", "Macedonia"],
  ["MG", "Madagascar"],
  ["MW", "Malawi"],
  ["MY", "Malaysia"],
  ["MV", "Maldives"],
  ["ML", "Mali"],
  ["MT", "Malta"],
  ["MQ", "Martinique"],
  ["MR", "Mauritania"],
  ["MU", "Mauritius"],
  ["MX", "Mexico"],
  ["MD", "Moldova"],
  ["MC", "Monaco"],
  ["MN", "Mongolia"],
  ["ME", "Montenegro"],
  ["MS", "Montserrat"],
  ["MA", "Morocco"],
  ["MZ", "Mozambique"],
  ["MM", "Myanmar"],
  ["NA", "Namibia"],
  ["NR", "Nauru"],
  ["NP", "Nepal"],
  ["NL", "Netherlands"],
  ["AN", "Netherlands Antilles"],
  ["NC", "New Caledonia"],
  ["NZ", "New Zealand"],
  ["NI", "Nicaragua"],
  ["NE", "Niger"],
  ["NG", "Nigeria"],
  ["KP", "North Korea"],
  ["MP", "Northern Mariana Islands"],
  ["NO", "Norway"],
  ["OM", "Oman"],
  ["PK", "Pakistan"],
  ["PW", "Palau"],
  ["PS", "Palestine"],
  ["PA", "Panama"],
  ["PG", "Papua New Guinea"],
  ["PY", "Paraguay"],
  ["PE", "Peru"],
  ["PH", "Philippines"],
  ["PL", "Poland"],
  ["PT", "Portugal"],
  ["PR", "Puerto Rico"],
  ["QA", "Qatar"],
  ["RE", "Reunion"],
  ["RO", "Romania"],
  ["RU", "Russia"],
  ["RW", "Rwanda"],
  ["BL", "Saint Barthélemy"],
  ["KN", "Saint Kitts and Nevis"],
  ["SPM", "Saint-Pierre et Miquelon"],
  ["WS", "Samoa"],
  ["SM", "San Marino"],
  ["ST", "Sao Tome And Principe"],
  ["SA", "Saudi Arabia"],
  ["SN", "Senegal"],
  ["RS", "Serbia"],
  ["SC", "Seychelles"],
  ["SL", "Sierra Leone"],
  ["SG", "Singapore"],
  ["SX", "Sint Maarten"],
  ["SK", "Slovakia"],
  ["SI", "Slovenia"],
  ["SB", "Solomon Islands"],
  ["SO", "Somalia"],
  ["ZA", "South Africa"],
  ["KR", "South Korea"],
  ["SS", "South Sudan"],
  ["ES", "Spain"],
  ["LK", "Sri Lanka"],
  ["LC", "St Lucia"],
  ["VC", "St Vincent And The Grenadines"],
  ["SD", "Sudan"],
  ["SR", "Suriname"],
  ["SZ", "Swaziland"],
  ["SE", "Sweden"],
  ["CH", "Switzerland"],
  ["SY", "Syria"],
  ["TW", "Taiwan"],
  ["TJ", "Tajikistan"],
  ["TZ", "Tanzania"],
  ["TH", "Thailand"],
  ["TG", "Togo"],
  ["TO", "Tonga"],
  ["TT", "Trinidad And Tobago"],
  ["TN", "Tunisia"],
  ["TR", "Turkey"],
  ["TM", "Turkmenistan"],
  ["TC", "Turks and Caicos Islands"],
  ["TV", "Tuvalu"],
  ["UG", "Uganda"],
  ["UA", "Ukraine"],
  ["AE", "United Arab Emirates"],
  ["GB", "United Kingdom"],
  ["US", "United States"],
  ["UY", "Uruguay"],
  ["UZ", "Uzbekistan"],
  ["VU", "Vanuatu"],
  ["VE", "Venezuela"],
  ["VN", "Vietnam"],
  ["VG", "Virgin Islands, British"],
  ["VI", "Virgin Islands, U.S."],
  ["YE", "Yemen"],
  ["ZM", "Zambia"],
  ["ZW", "Zimbabwe"],
  ["en", "English"],
  ["he_IL", "Hebrew"],
];
export const phoneOptions = [
  ["United States", "1", "us"],
  ["Canada", "1", "ca"],
  ["United Kingdom", "44", "gb"],
  ["Afghanistan (‫افغانستان‬‎)", "93", "af"],
  ["Albania (Shqipëri)", "355", "al"],
  ["Algeria (‫الجزائر‬‎)", "213", "dz"],
  ["American Samoa", "1684", "as"],
  ["Andorra", "376", "ad"],
  ["Angola", "244", "ao"],
  ["Anguilla", "1264", "ai"],
  ["Antigua and Barbuda", "1268", "ag"],
  ["Argentina", "54", "ar"],
  ["Armenia (Հայաստան)", "374", "am"],
  ["Aruba", "297", "aw"],
  ["Australia", "61", "au"],
  ["Austria (Österreich)", "43", "at"],
  ["Azerbaijan (Azərbaycan)", "994", "az"],
  ["Bahamas", "1242", "bs"],
  ["Bahrain (‫البحرين‬‎)", "973", "bh"],
  ["Bangladesh (বাংলাদেশ)", "880", "bd"],
  ["Barbados", "1246", "bb"],
  ["Belarus (Беларусь)", "375", "by"],
  ["Belgium (België)", "32", "be"],
  ["Belize", "501", "bz"],
  ["Benin (Bénin)", "229", "bj"],
  ["Bermuda", "1441", "bm"],
  ["Bhutan (འབྲུག)", "975", "bt"],
  ["Bolivia", "591", "bo"],
  ["Bosnia and Herzegovina (Босна и Херцеговина)", "387", "ba"],
  ["Botswana", "267", "bw"],
  ["Brazil (Brasil)", "55", "br"],
  ["British Indian Ocean Territory", "246", "io"],
  ["British Virgin Islands", "1284", "vg"],
  ["Brunei", "673", "bn"],
  ["Bulgaria (България)", "359", "bg"],
  ["Burkina Faso", "226", "bf"],
  ["Burundi (Uburundi)", "257", "bi"],
  ["Cambodia (កម្ពុជា)", "855", "kh"],
  ["Cameroon (Cameroun)", "237", "cm"],
  ["Canada", "1", "ca"],
  ["Cape Verde (Kabu Verdi)", "238", "cv"],
  ["Caribbean Netherlands", "599", "bq"],
  ["Cayman Islands", "1345", "ky"],
  ["Central African Republic (République centrafricaine)</span", "236", "cf"],
  ["Chad (Tchad)", "235", "td"],
  ["Chile", "56", "cl"],
  ["China (中国)", "86", "cn"],
  ["Christmas Island", "61", "cx"],
  ["Cocos (Keeling) Islands", "61", "cc"],
  ["Colombia", "57", "co"],
  ["Comoros (‫جزر القمر‬‎)", "269", "km"],
  ["Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)", "243", "cd"],
  ["Congo (Republic) (Congo-Brazzaville)", "242", "cg"],
  ["Cook Islands", "682", "ck"],
  ["Costa Rica", "506", "cr"],
  ["Côte d’Ivoire", "225", "ci"],
  ["Croatia (Hrvatska)", "385", "hr"],
  ["Cuba", "53", "cu"],
  ["Curaçao", "599", "cw"],
  ["Cyprus (Κύπρος)", "357", "cy"],
  ["Czech Republic (Česká republika)", "420", "cz"],
  ["Denmark (Danmark)", "45", "dk"],
  ["Djibouti", "253", "dj"],
  ["Dominica", "1767", "dm"],
  ["Dominican Republic (República Dominicana)", "1", "do"],
  ["Ecuador", "593", "ec"],
  ["Egypt (‫مصر‬‎)", "20", "eg"],
  ["El Salvador", "503", "sv"],
  ["Equatorial Guinea (Guinea Ecuatorial)", "240", "gq"],
  ["Eritrea", "291", "er"],
  ["Estonia (Eesti)", "372", "ee"],
  ["Ethiopia", "251", "et"],
  ["Falkland Islands (Islas Malvinas)", "500", "fk"],
  ["Faroe Islands (Føroyar)", "298", "fo"],
  ["Fiji", "679", "fj"],
  ["Finland (Suomi)", "358", "fi"],
  ["France", "33", "fr"],
  ["French Guiana (Guyane française)", "594", "gf"],
  ["French Polynesia (Polynésie française)", "689", "pf"],
  ["Gabon", "241", "ga"],
  ["Gambia", "220", "gm"],
  ["Georgia (საქართველო)", "995", "ge"],
  ["Germany (Deutschland)", "49", "de"],
  ["Ghana (Gaana)", "233", "gh"],
  ["Gibraltar", "350", "gi"],
  ["Greece (Ελλάδα)", "30", "gr"],
  ["Greenland (Kalaallit Nunaat)", "299", "gl"],
  ["Grenada", "1473", "gd"],
  ["Guadeloupe", "590", "gp"],
  ["Guam", "1671", "gu"],
  ["Guatemala", "502", "gt"],
  ["Guernsey", "44", "gg"],
  ["Guinea (Guinée)", "224", "gn"],
  ["Guinea-Bissau (Guiné Bissau)", "245", "gw"],
  ["Guyana", "592", "gy"],
  ["Haiti", "509", "ht"],
  ["Honduras", "504", "hn"],
  ["Hong Kong (香港)", "852", "hk"],
  ["Hungary (Magyarország)", "36", "hu"],
  ["Iceland (Ísland)", "354", "is"],
  ["India (भारत)", "91", "in"],
  ["Indonesia", "62", "id"],
  ["Iran (‫ایران‬‎)", "98", "ir"],
  ["Iraq (‫العراق‬‎)", "964", "iq"],
  ["Ireland", "353", "ie"],
  ["Isle of Man", "44", "im"],
  ["Israel (‫ישראל‬‎)", "972", "il"],
  ["Italy (Italia)", "39", "it"],
  ["Jamaica", "1876", "jm"],
  ["Japan (日本)", "81", "jp"],
  ["Jersey", "44", "je"],
  ["Jordan (‫الأردن‬‎)", "962", "jo"],
  ["Kazakhstan (Казахстан)", "7", "kz"],
  ["Kenya", "254", "ke"],
  ["Kiribati", "686", "ki"],
  ["Kosovo", "383", "xk"],
  ["Kuwait (‫الكويت‬‎)", "965", "kw"],
  ["Kyrgyzstan (Кыргызстан)", "996", "kg"],
  ["Laos (ລາວ)", "856", "la"],
  ["Latvia (Latvija)", "371", "lv"],
  ["Lebanon (‫لبنان‬‎)", "961", "lb"],
  ["Lesotho", "266", "ls"],
  ["Liberia", "231", "lr"],
  ["Libya (‫ليبيا‬‎)", "218", "ly"],
  ["Liechtenstein", "423", "li"],
  ["Lithuania (Lietuva)", "370", "lt"],
  ["Luxembourg", "352", "lu"],
  ["Macau (澳門)", "853", "mo"],
  ["Macedonia (FYROM) (Македонија)", "389", "mk"],
  ["Madagascar (Madagasikara)", "261", "mg"],
  ["Malawi", "265", "mw"],
  ["Malaysia", "60", "my"],
  ["Maldives", "960", "mv"],
  ["Mali", "223", "ml"],
  ["Malta", "356", "mt"],
  ["Marshall Islands", "692", "mh"],
  ["Martinique", "596", "mq"],
  ["Mauritania (‫موريتانيا‬‎)", "222", "mr"],
  ["Mauritius (Moris)", "230", "mu"],
  ["Mayotte", "262", "yt"],
  ["Mexico (México)", "52", "mx"],
  ["Micronesia", "691", "fm"],
  ["Moldova (Republica Moldova)", "373", "md"],
  ["Monaco", "377", "mc"],
  ["Mongolia (Монгол)", "976", "mn"],
  ["Montenegro (Crna Gora)", "382", "me"],
  ["Montserrat", "1664", "ms"],
  ["Morocco (‫المغرب‬‎)", "212", "ma"],
  ["Mozambique (Moçambique)", "258", "mz"],
  ["Myanmar (Burma) (မြန်မာ)", "95", "mm"],
  ["Namibia (Namibië)", "264", "na"],
  ["Nauru", "674", "nr"],
  ["Nepal (नेपाल)", "977", "np"],
  ["Netherlands (Nederland)", "31", "nl"],
  ["New Caledonia (Nouvelle-Calédonie)", "687", "nc"],
  ["New Zealand", "64", "nz"],
  ["Nicaragua", "505", "ni"],
  ["Niger (Nijar)", "227", "ne"],
  ["Nigeria", "234", "ng"],
  ["Niue", "683", "nu"],
  ["Norfolk Island", "672", "nf"],
  ["North Korea (조선 민주주의 인민 공화국)", "850", "kp"],
  ["Northern Mariana Islands", "1670", "mp"],
  ["Norway (Norge)", "47", "no"],
  ["Oman (‫عُمان‬‎)", "968", "om"],
  ["Pakistan (‫پاکستان‬‎)", "92", "pk"],
  ["Palau", "680", "pw"],
  ["Palestine (‫فلسطين‬‎)", "970", "ps"],
  ["Panama (Panamá)", "507", "pa"],
  ["Papua New Guinea", "675", "pg"],
  ["Paraguay", "595", "py"],
  ["Peru (Perú)", "51", "pe"],
  ["Philippines", "63", "ph"],
  ["Poland (Polska)", "48", "pl"],
  ["Portugal", "351", "pt"],
  ["Puerto Rico", "1", "pr"],
  ["Qatar (‫قطر‬‎)", "974", "qa"],
  ["Réunion (La Réunion)", "262", "re"],
  ["Romania (România)", "40", "ro"],
  ["Russia (Россия)", "7", "ru"],
  ["Rwanda", "250", "rw"],
  ["Saint Barthélemy (Saint-Barthélemy)", "590", "bl"],
  ["Saint Helena", "290", "sh"],
  ["Saint Kitts and Nevis", "1869", "kn"],
  ["Saint Lucia", "1758", "lc"],
  ["Saint Martin (Saint-Martin (partie française))", "590", "mf"],
  ["Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)</span", "508", "pm"],
  ["Saint Vincent and the Grenadines", "1784", "vc"],
  ["Samoa", "685", "ws"],
  ["San Marino", "378", "sm"],
  ["São Tomé and Príncipe (São Tomé e Príncipe)", "239", "st"],
  ["Saudi Arabia (‫المملكة العربية السعودية‬‎)", "966", "sa"],
  ["Senegal (Sénégal)", "221", "sn"],
  ["Serbia (Србија)", "381", "rs"],
  ["Seychelles", "248", "sc"],
  ["Sierra Leone", "232", "sl"],
  ["Singapore", "65", "sg"],
  ["Sint Maarten", "1721", "sx"],
  ["Slovakia (Slovensko)", "421", "sk"],
  ["Slovenia (Slovenija)", "386", "si"],
  ["Solomon Islands", "677", "sb"],
  ["Somalia (Soomaaliya)", "252", "so"],
  ["South Africa", "27", "za"],
  ["South Korea (대한민국)", "82", "kr"],
  ["South Sudan (‫جنوب السودان‬‎)", "211", "ss"],
  ["Spain (España)", "34", "es"],
  ["Sri Lanka (ශ්‍රී ලංකාව)", "94", "lk"],
  ["Sudan (‫السودان‬‎)", "249", "sd"],
  ["Suriname", "597", "sr"],
  ["Svalbard and Jan Mayen", "47", "sj"],
  ["Swaziland", "268", "sz"],
  ["Sweden (Sverige)", "46", "se"],
  ["Switzerland (Schweiz)", "41", "ch"],
  ["Syria (‫سوريا‬‎)", "963", "sy"],
  ["Taiwan (台灣)", "886", "tw"],
  ["Tajikistan", "992", "tj"],
  ["Tanzania", "255", "tz"],
  ["Thailand (ไทย)", "66", "th"],
  ["Timor-Leste", "670", "tl"],
  ["Togo", "228", "tg"],
  ["Tokelau", "690", "tk"],
  ["Tonga", "676", "to"],
  ["Trinidad and Tobago", "1868", "tt"],
  ["Tunisia (‫تونس‬‎)", "216", "tn"],
  ["Turkey (Türkiye)", "90", "tr"],
  ["Turkmenistan", "993", "tm"],
  ["Turks and Caicos Islands", "1649", "tc"],
  ["Tuvalu", "688", "tv"],
  ["U.S. Virgin Islands", "1340", "vi"],
  ["Uganda", "256", "ug"],
  ["Ukraine (Україна)", "380", "ua"],
  ["United Arab Emirates (‫الإمارات العربية المتحدة‬‎)</span", "971", "ae"],
  ["United Kingdom", "44", "gb"],
  ["United States", "1", "us"],
  ["Uruguay", "598", "uy"],
  ["Uzbekistan (Oʻzbekiston)", "998", "uz"],
  ["Vanuatu", "678", "vu"],
  ["Vatican City (Città del Vaticano)", "39", "va"],
  ["Venezuela", "58", "ve"],
  ["Vietnam (Việt Nam)", "84", "vn"],
  ["Wallis and Futuna", "681", "wf"],
  ["Western Sahara (‫الصحراء الغربية‬‎)", "212", "eh"],
  ["Yemen (‫اليمن‬‎)", "967", "ye"],
  ["Zambia", "260", "zm"],
  ["Zimbabwe", "263", "zw"],
  ["Åland Islands", "358", "ax"],
];
