
import footerComp from "./components/footer-comp.vue";
import { defineComponent } from "@vue/composition-api";
import moment from "moment";

export default defineComponent({
  components: { footerComp },
  name: "App",
  beforeMount() {
    this.$gtag.optIn();
    // (function (w, d, s, l, i) {
    //   w[l] = w[l] || [];
    //   w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    //   var f = d.getElementsByTagName(s)[0] as any,
    //     j = d.createElement(s) as any,
    //     dl = l != "dataLayer" ? "&l=" + l : "";
    //   j.async = true;
    //   j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    //   f.parentNode.insertBefore(j, f);
    // })(window, document, "script", "dataLayer", "GTM-P5X653C");
  },
  watch: {
    "$route.fullPath": {
      handler(val) {
        const { changeLocale } = this as any;

        if (val.includes("/en/")) return changeLocale("en");
        if (val.includes("/he/")) changeLocale("he");
      },
      immediate: true,
    },
  },
  methods: {
    changeLocale(lang: string): void {
      this.$i18n.locale = lang;
      moment.locale(lang);
    },
  },
});
