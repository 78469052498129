<template>
  <div class="home">
    <popup v-model="popupImage" width="90%"><img :src="popupImage" /></popup>
    <popup width="90%" v-model="popupVideo">
      <div class="video" v-html="tour.videoLink"></div>
    </popup>
    <div class="hero">
      <router-link to="/" class="logo-wrap">
        <img src="../assets/logo-white.svg" />
      </router-link>
      <div class="cont-top">
        <a
          @click="sendAnalytics('click', 'button', 'whatsapp')"
          class="whatsapp"
          href="https://wa.me/message/YC3WILNVSVUXN1"
        >
          <img src="../assets/whatsapp.svg" />
          Start Chat
        </a>
        <a class="cont-phone white" href="tel:+972-50-595-2713"
          >+972-50-595-2713</a
        >
      </div>
      <router-link class="lang" :to="`/he/${$route.params.id}`"
        >עברית</router-link
      >

      <div class="dark-top">
        <div class="hero-txt-box">
          <h1 class="main-head">{{ tour.nameEnglish }}</h1>
          <!-- <router-link
            :to="`/BookingPage?checkfrontProductID=${tour.checkfrontProductID}&language=en`"
            class="book"
            >Book Now!</router-link
          > -->
          <p
            @click="
              goto('#tickets');
              sendAnalytics('click', 'button', 'booknow');
            "
            class="book"
          >
            Book Now!
          </p>

          <div class="playbtn" @click="popupVideo = true"></div>
        </div>
      </div>
      <div class="main-image-wrap" v-if="tour.coverImage">
        <video
          style="pointer-events: none"
          v-if="(tour.coverImage || {}).extension === '.mp4'"
          class="main-image"
          autoplay
          loop
          muted
          playsinline
          :poster="tour.fallback.imageURL"
          preload="metadata"
        >
          <source :src="tour.coverImageMov.imageURL" type="video/mp4" />
          <source :src="tour.coverImageMov.imageURL" type="video/mov" />
          <source :src="tour.coverImage.imageURL" type="video/mp4" />
          <source :src="tour.coverImage.imageURL" type="video/webm" />
        </video>
        <img v-else :src="tour.coverImage.imageURL" class="main-image" />
      </div>
    </div>
    <div class="info-box">
      <div class="left-info">
        <div v-html="tour.descriptionEnglish"></div>
        <!-- <a href="#tickets" class="book">Book Now!</a> -->
        <p
          @click="
            goto('#tickets');
            sendAnalytics('click', 'button', 'booknow');
          "
          class="book"
        >
          Book Now!
        </p>
      </div>

      <div class="right-info">
        <div class="points-wrap">
          <div
            v-for="point in tour.bulletPoint"
            :key="point._id"
            class="info-list-item"
          >
            <div class="info-list-icon-wrap">
              <img
                v-if="point.icon[0].item.icon.imageURL"
                :src="point.icon[0].item.icon.imageURL"
              />
            </div>
            <div class="list-txt">
              <p>
                {{ point.lightText }}
                <span class="bold">{{ point.boldText }}</span>
              </p>
              <div
                class="list-txt-info-box"
                v-if="point.list"
                v-html="point.list"
              ></div>
            </div>
          </div>

          <div class="side-line"></div>
        </div>
        <p @click="goto('#otherExperiences')" class="bluebtn">
          Other Experiences
        </p>
      </div>
    </div>
    <div class="gallery">
      <div
        class="gal-image"
        v-for="image in tour.imageGallery"
        :key="image.imageURL"
        @click="popupImage = image.imageURL"
      >
        <img :src="image.imageURL" height="" width="" class="gallery-img" />
      </div>
    </div>
    <div class="tickets" id="tickets">
      <div class="tickets-head-wrap">
        <h3 class="tickets-head">Purchase tickets</h3>
        <p>Check ticket availibility below to purchase a ticket</p>
      </div>
      <!-- <div class="tickets-wrap">
          <div class="steps-wrap">
            <div class="step-line"></div>
            <div class="step-dot-wrap">
              <div class="step-dot active"><p>01</p></div>
              <p>Date</p>
            </div>
            <div class="step-dot-wrap">
              <div class="step-dot"><p>02</p></div>
              <p>Tickets</p>
            </div>
            <div class="step-dot-wrap">
              <div class="step-dot"><p>03</p></div>
              <p>{{ $t("Payment") }}</p>
            </div>
          </div>
        </div> -->
      <!-- <div class="tickets-box"> -->
      <router-view :productID="tour.checkfrontProductID" :language="'en'" />
      <!-- </div> -->
      <!-- </div> -->
      <!-- <div class="tickets-wrap">
        <div class="tickets-head-wrap">
          <h3 class="tickets-head">Purchase tickets</h3>
          <p>
            For waking up the past a special VIRTUAL REALITY tour at the
            Davidson Center
          </p>
          <div class="steps-wrap">
            <div class="step-line"></div>
            <div class="step-dot-wrap">
              <div class="step-dot active"><p>01</p></div>
              <p>Date</p>
            </div>
            <div class="step-dot-wrap">
              <div class="step-dot active"><p>02</p></div>
              <p>Tickets</p>
            </div>
            <div class="step-dot-wrap">
              <div class="step-dot"><p>03</p></div>
              <p>{{ $t("Payment") }}</p>
            </div>
          </div>
        </div>
        <div class="tickets-box">
          <div class="ticket-row top">
            <div class="row-part"><p>Ticket Type</p></div>
            <div class="row-part"><p>Price</p></div>
            <div class="row-part"><p>Amount</p></div>
            <div class="row-part"><p>Total</p></div>
          </div>
          <div class="ticket-row">
            <div class="row-part"><p>senior</p></div>
            <div class="row-part"><p>50 NIS</p></div>
            <div class="row-part">
              <input placeholder="0" type="number" class="num-tickets" />
            </div>
            <div class="row-part">
              <p>20</p>
            </div>
          </div>
          <div class="ticket-row">
            <div class="row-part"><p>Adult</p></div>
            <div class="row-part"><p>50 NIS</p></div>
            <div class="row-part">
              <input placeholder="0" type="number" class="num-tickets" />
            </div>
            <div class="row-part">
              <p>20</p>
            </div>
          </div>
          <div class="ticket-row">
            <div class="row-part"><p>Child</p></div>
            <div class="row-part"><p>50 NIS</p></div>
            <div class="row-part">
              <input placeholder="0" type="number" class="num-tickets" />
            </div>
            <div class="row-part">
              <p>20</p>
            </div>
          </div>
          <div class="ticket-row">
            <div class="row-part"><p>Student</p></div>
            <div class="row-part"><p>50 NIS</p></div>
            <div class="row-part">
              <input placeholder="0" type="number" class="num-tickets" />
            </div>
            <div class="row-part">
              <p>20</p>
            </div>
          </div>
          <div class="ticket-row">
            <div class="row-part"><p>Other</p></div>
            <div class="row-part"><p>50 NIS</p></div>
            <div class="row-part">
              <input placeholder="0" type="number" class="num-tickets" />
            </div>
            <div class="row-part">
              <p>20</p>
            </div>
          </div>
          <div class="ticket-row last">
            <div class="row-part"><p>senior</p></div>
            <div class="row-part"></div>
            <div class="row-part"></div>
            <div class="row-part">
              <p>20</p>
            </div>
          </div>
        </div>
        <button type="button" class="next back">Back</button
        ><button type="button" class="next">Next</button>
      </div> -->
    </div>
    <div id="otherExperiences">
      <h3 class="tickets-head other">Check out our other Experiences</h3>

      <carousel
        v-if="otherTours.length"
        class="other-tours"
        :nav="false"
        :loop="true"
        :autoplay="true"
        :autoplayTimeout="7000"
        :responsive="{
          0: { items: 1 },
          800: { items: Math.min(3, otherTours.length) },
        }"
      >
        <div
          class="other-tour-link-wrap"
          v-for="otherTour in otherTours"
          :key="otherTour._id"
        >
          <a class="other-tour" :href="'/en/' + otherTour._id">
            <div class="about-tour-link-txt-box">
              <p>{{ otherTour.nameEnglish }}</p>
              <!-- <div class="small-fbtn">Purchase Tickets</div> -->
              <p>Purchase Tickets</p>
            </div>
            <video
              playsinline
              style="pointer-events: none"
              autoplay
              muted
              loop
              class="other-image"
              :poster="otherTour.fallback.imageURL"
              v-if="(otherTour.coverImage || {}).extension === '.mp4'"
            >
              <source
                :src="otherTour.coverImageMov.imageURL"
                type="video/mp4"
              />
              <source
                :src="otherTour.coverImageMov.imageURL"
                type="video/mov"
              />
              <source :src="otherTour.coverImage.imageURL" type="video/mp4" />
              <source :src="otherTour.coverImage.imageURL" type="video/webm" />
            </video>
            <img
              v-else
              :src="otherTour.coverImage.imageURL"
              class="other-image"
            />
          </a>
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

// import Checkfront from "@/components/CheckFront";

export default {
  components: {
    // Checkfront,
    carousel,
  },
  data() {
    return {
      videoPlaying: false,
      popupImage: "",
      popupVideo: "",
      tour: { descriptionHebrew: "סיור מיוחד" },
      otherTours: [],
    };
  },
  methods: {
    goto(refName) {
      const isID = !!refName.match(/^#/);
      var element;
      if (isID) element = document.querySelector(refName);
      else element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo({ left: 0, top, behavior: "smooth" });
    },
    loadStartVideo(video) {
      const isVideoPlaying = (video) =>
        !!(
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > 2
        );
      if (isVideoPlaying) {
        document.addEventListener("click", () => {
          video.target.play();
        });
      }
    },
    sendAnalytics(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },
  },

  async beforeMount() {
    let { id: currentTourID } = this.$route.params;

    let { id } = this.$route.params;
    console.log(id);
    let { data } = await this.$db
      .collection("tours")
      .item(currentTourID != ":id" ? currentTourID : "620b748352951ebd286bd024")
      .everything()
      .get();

    let { data: otherTours } = await this.$db
      .collection("tours")
      .query("_id", "!=", currentTourID)
      .query("draft", "!=", true)

      .get();

    let { data: faq } = await this.$db.collection("faq").get();

    this.otherTours = otherTours;
    this.tour = data;
    this.faq = faq;
  },
  name: "English",
};
</script>
