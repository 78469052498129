var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"about-hero"},[_c('router-link',{staticClass:"logo-wrap",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../assets/logo-white.svg"),"height":"","width":""}})]),_c('div',{staticClass:"cont-top"},[_c('a',{staticClass:"whatsapp",attrs:{"href":"https://wa.me/message/YC3WILNVSVUXN1"},on:{"click":function($event){return _vm.sendAnalytics('click', 'button', 'whatsapp')}}},[_c('img',{attrs:{"src":require("../assets/whatsapp.svg"),"height":"","width":""}}),_vm._v(" Start Chat ")]),_c('a',{staticClass:"cont-phone",attrs:{"href":"tel:+972-50-595-2713"}},[_vm._v("+972-50-595-2713")])]),_c('router-link',{staticClass:"lang",attrs:{"to":`/he/about`}},[_vm._v("עברית")]),_vm._m(0),_c('img',{staticClass:"king",attrs:{"src":require("../assets/vr-boy.png"),"alt":"vr"}}),_c('img',{staticClass:"about-bg",attrs:{"src":require("../assets/bgw.jpg")}})],1),(_vm.tours.length)?_c('carousel',{staticClass:"about-tours-wrap",attrs:{"nav":false,"autoplay":true,"responsive":{
    0: { items: 1 },
    800: { items: Math.min(3, _vm.tours.length) },
  }}},_vm._l((_vm.tours),function(tour){return _c('router-link',{key:tour._id,staticClass:"about-tour-link",attrs:{"to":`/en/${tour._id}`,"href":""}},[_c('div',{staticClass:"about-tour-link-txt-box"},[_c('p',{staticClass:"about-tour-link-head"},[_vm._v(_vm._s(tour.nameEnglish))]),_c('p',[_vm._v("Purchase Tickets")])]),(tour.coverImage.extension === '.mp4')?_c('video',{staticStyle:{"pointer-events":"none"},attrs:{"playsinline":"","autoplay":"","mute":"","loop":""}},[_c('source',{attrs:{"src":tour.coverImage.imageURL,"type":"video/mp4"}})]):_c('img',{attrs:{"src":tour.coverImage.imageURL}})])}),1):_vm._e(),_vm._m(1),_vm._m(2),_vm._m(3)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-hero-wrap"},[_c('div',{staticClass:"about-hero-txt-wrap"},[_c('h1',{staticClass:"about-head"},[_vm._v("Ancient Israel Brought to Life!")]),_c('p',{staticClass:"about-sub"},[_vm._v(" Our host will guide you on your virtual augmented journey as you walk the locations of Biblical dramas. Witness the saga through 4000 years at the City of David and Ancient Jerusalem. Discover Israel’s history, legends and characters like never before! ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"about-mid-head"},[_vm._v(" “Amazing 3D sound and vision!” "),_c('br'),_vm._v("“Spectacular Jerusalem walking tour” ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-video-wrap"},[_c('div',{staticClass:"video"},[_c('iframe',{attrs:{"width":"560","height":"315","src":"https://www.youtube.com/embed/3f1_fCbtyng?controls=0","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-testimonials-wrap"},[_c('div',{staticClass:"about-testimonial"},[_c('p',[_vm._v(" \"Unlike any other tour out there. Yaturu’s stunning animation not only rebuilds the ancient sites before your eyes, but brings you into the personal drama of the biblical heroes.\" ")]),_c('div',{staticClass:"about-stars-wrap"},[_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}}),_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}}),_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}}),_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}})]),_c('p',{staticClass:"about-name"},[_vm._v("Judy Maynard")]),_c('p',{staticClass:"about-loc"},[_vm._v("Syndey, Australia")])]),_c('div',{staticClass:"about-testimonial"},[_c('p',[_vm._v(" \"Fascinating mix of history, archeology and storytelling. Thrilling to see the old stories comes to life! Great for the whole family. \" ")]),_c('div',{staticClass:"about-stars-wrap"},[_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}}),_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}}),_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}}),_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}}),_c('img',{staticClass:"about-star",attrs:{"src":require("../assets/Star.svg")}})]),_c('p',{staticClass:"about-name"},[_vm._v("Yaron Schwartz")]),_c('p',{staticClass:"about-loc"},[_vm._v("Rehovot, Israel")])])])
}]

export { render, staticRenderFns }