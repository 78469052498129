
import { defineComponent } from "@vue/composition-api";
import { contryOptions, phoneOptions } from "../../assets/formData";
import "@braid/vue-formulate/dist/snow.min.css";
import "./style.css";

const contryOptionsMapped = contryOptions.map((c) => ({
  value: c[0],
  label: c[1],
}));

export default defineComponent({
  name: "CheckFrontForm",
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  emits: ["input"],
  data() {
    return {
      form: {
        customer_name: "",
        customer_email: "",
        countryCode: "",
        customer_phone: "",
        address: "",
        customer_city: "",
        state: "",
        zip: "",
        customer_country: "",
        note: "",
        booking_language:
          this.$route.name === "Project English" ? "en" : "he_IL",
      },
      contryOptionsMapped,
      bookingLanguageOptions: {
        en: "English",
        he_IL: "Hebrew",
      },
      phoneOptions,
      termsAndConditions: false,
    };
  },
  mounted() {
    this.$emit("input", this.form);
  },
  watch: {
    form() {
      this.$emit("input", this.form);
    },
  },
  methods: {
    // onSubmit() {},
    // onReset() {},
  },
});
