var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lds-ring"},[_c('div',{style:({
      'border-color': `${_vm.color} transparent transparent transparent`,
    })}),_c('div',{style:({
      'border-color': `${_vm.color} transparent transparent transparent`,
    })}),_c('div',{style:({
      'border-color': `${_vm.color} transparent transparent transparent`,
    })}),_c('div',{style:({
      'border-color': `${_vm.color} transparent transparent transparent`,
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }