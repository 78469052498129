
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "numToggle",
  data() {
    return {
      numValue: 0,
      timeout: setTimeout(() => undefined, 0),
    };
  },
  props: {
    value: { type: [Number, String], default: 0 },
    required: Boolean,
    min: { type: [Number, String], default: 0 },
    max: [Number, String],
  },
  mounted() {
    this.numValue = 0;
    this.$emit("input", 0);
  },
  methods: {
    useDebounceFn(fn) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        fn();
      }, 150);
    },
    toggleUp() {
      if (this.max && this.numValue >= this.max) {
        // const wantsToChooseAnotherTime = confirm(
        this.$emit(
          "alert",
          `${this.$t("Sorry, only a total of")} ${this.max} ${this.$t(
            "ticket available please choose another time or contact us for larger group bookings"
          )}`
        );
        // if (wantsToChooseAnotherTime) this.$emit("stepBack");
        return;
      }
      this.useDebounceFn(() => {
        this.numValue++;
        this.$emit("input", this.numValue || 0);
      });
    },
    toggleDown() {
      if (!(this.numValue > this.min || 0)) return;
      this.useDebounceFn(() => {
        this.numValue--;
        this.$emit("input", this.numValue || 0);
      });
    },
  },
  watch: {
    max(max) {
      if (this.numValue > max || 0) this.numValue = max;
    },
    value() {
      if (this.numValue != this.value) this.numValue = +this.value;
    },
    numValue(val) {
      if (val === parseInt((`${val}`?.match(/\d+/g) || []).join(""))) return;

      let v: number | string = +(`${val}`?.match(/\d+/g) || []).join("") || 0;
      if (v < this.min) v = this.min;
      this.numValue = +v;
      this.$emit("input", v || 0);
    },
  },
});
