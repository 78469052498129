
import { defineComponent } from "@vue/composition-api";
import moment from "moment";
export default defineComponent({
  name: "StatusBox",
  props: {
    status: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    moment,
  }),
});
