import Vue from 'vue';

import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router';
import { BagelDB } from '@bageldb/bagel-db';
// import TOKEN from './keys'
import Popup from 'vue-bgl-popup';
import VueCompositionAPI from '@vue/composition-api';
import VCalendar from 'v-calendar';

import vueCountryCode from 'vue-country-code';
import env from '../env';
import i18n from './i18n';
Vue.use(vueCountryCode);
import VueFormulate from '@braid/vue-formulate';
import { he, en } from '@braid/vue-formulate-i18n';
Vue.use(VueFormulate, { plugins: [he, en] });
Vue.use(VCalendar);
Vue.use(VueCompositionAPI);
import VueGtag from 'vue-gtag';

const params = {
  bootstrap: true,
  appName: 'Yaturu',
  pageTrackerScreenviewEnabled: true,
  enabled: true,
  send_page_view: true,
  debug_mode: true /* process.env.NODE_ENV === "development" */,
  ad_storage: 'granted',
  analytics_storage: 'granted',
};

Vue.use(
  VueGtag,
  {
    config: { id: env.VUE_APP_GTAG, params },
    // includes: [
    //   { id: env.VUE_APP_GTAG },
    //   {
    //     // id: "GTM-P5X653C",
    //     id: "GTM-MWX2PCS",
    //     params,
    //   },
    // ],
    ...params,
  },
  router
);
console.log('VUE_APP_GTAG', env.VUE_APP_GTAG);
Vue.component('popup', Popup);

// Add types to Vue
declare module 'vue/types/vue' {
  interface Vue {
    $db: BagelDB;
  }
}
Vue.prototype.$db = new BagelDB(env.VUE_APP_BAGEL_TOKEN);

Vue.use(VueRouter);
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
