export default {
  VUE_APP_BAGEL_TOKEN:
    "eyJhbGciOiJSUzI1NiIsImtpZCI6ImFwaS1rZXkiLCJ0eXAiOiJKV1QifQ.eyJvcmdhbml6YXRpb25JRCI6ImM4NW1oZ3EyM2FrczczYzkycGVnIiwicHJvamVjdElEIjoiYzg1bWhqYTIzYWtzNzNjOTJwaDAiLCJhdWQiOiJodHRwczovL2FwcC5iYWdlbGRiLmNvbSIsImp0aSI6IjEzZjM2OTBjLTRiMGEtNDVlMS1iM2ZlLWJjNTk3Zjk5OGUwYiIsImlzcyI6Imh0dHBzOi8vYXV0aGVudGljYXRpb24uYmFnZWxkYi5jbyIsInN1YiI6ImM4NW1oamEyM2FrczczYzkycGgwIn0.Xj76LgNjnJ7vrt-xR7KqOsW-5u2FvwI7V0nhHvqO8LJlpc5JvQCn_Ef78-4fqRjg9yzQ7IxkSl6fQDY2Sjg4I4GyXyIcdIvVcobM-5ybA9cGhwwEtx7Hz3yO_L9X4b2LEPIouhYENNgBDz70OI1MsvCy4GZSGvc9abI7WPTSomhefhV0A-Rv8wcwW4BK4bKayPVob1b4ASSk7Pu9Q794hkfs4fxEsrKHEMHk9xWOUWYmxf8Gp3Ho-34-1kZynnSl5B232bGFaAh-XEjKooPAP1Xwd0tpBrSu23konB28_Sszq-0eQOTJutVNyv8DSQ_LLSMi1wnQqorzrBT5T6p611Bzdh8n06rUDXZMInbHc47u5hLms2wHRBksJsJ4Z2ty3pb-Sdw2QuNd_vkkf70QBA81KUfEWFopawi6ebmOMGlw61yReGHYgQdm8d3HZv4obXEYmTATEn_D2ZVGy7SRPzXasMMeVMDYmqpvFN6jzRaFfF_mOWu7KM8QUaw3Ij59dEJWXrqLGqO0hcdHGmoWr4fkbIFIaGjyR9lMY2LLiBlCkwoAkvdB_uKF-PpE31oIgBHAvT-uLvijbHwqmv_Cg8PSdMeewYHCJ5ki9B8t4Kjfj0YWj0ZhP68U_JWKjQIEVaREN7fVvrg1qwL68AzmCXZ7vWdF_xQQoATuyYVmRuQ",
  VUE_APP_PAYPAL_TOKEN:
    "AWWOM_FB2LWyv3f2YynSeTiySC1R_7vnjVCr1Sx3MpMzLUc6rtUo96U1fTgohnbfvFAE_pqCsjqEFgIP",
  VUE_APP_GTAG: "G-P2GCVR5EXW",
  // VUE_APP_GTAG: "UA-153603198-1", // ? origiinal gua id
  // VUE_APP_GTAG: "GTM-P5X653C",
  // VUE_APP_DEV_GTAG: "G-HDJP3CM7S7", // ? neveh's new ga account for testing
  VUE_APP_DEV_GTAG2: "UA-228580684-1",
};
